import { AnyAction } from "redux"

import { ISDG, ISysinfo } from "@api/schema"
import { PlatformActionTypes } from "@redux/actions/platform"
import { ILoadCollectionSuccessAction, INewUsecaseRequestSuccessAction } from "@redux/helper/actions"
import { ActionTypes, EntityType } from "@redux/reduxTypes"

export interface IPlatformState {
  authTTL: number
  backendCommit: string
  sdgs: ISDG[]
  sysinfo?: ISysinfo
}

export const initialPlatformState: IPlatformState = {
  authTTL: 0,
  backendCommit: '',
  sdgs: [],
}

export const platformReducer =
  (state: IPlatformState = initialPlatformState, action: AnyAction): IPlatformState => {
    switch (action.type?.toUpperCase()) {
      case PlatformActionTypes.SetBackendCommit.toUpperCase():
        return {
          ...state,
          backendCommit: action.hash
        }

      case ActionTypes.LoadCollectionSuccess:
        // if a collection was loaded, that EntityType is Sdgs
        // update the used sdgs of state.platform
        // therefor it is expected, that sdg-collections are only fetched to update the platform sdg list
        if ((action as INewUsecaseRequestSuccessAction).entityType === EntityType.Sdgs) {
          return { ...state, sdgs: (action as ILoadCollectionSuccessAction<ISDG>).collection["hydra:member"] }
        }

      case PlatformActionTypes.SetSysinfo.toUpperCase():
        return {
          ...state,
          sysinfo: action.sysinfo
        }

      case PlatformActionTypes.SetAuthTTL.toUpperCase():
        return {
          ...state,
          authTTL: action.ttl
        }

      default:
        return state
    }
  }
