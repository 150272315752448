
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppProps } from "next/app";
import NextNprogress from "nextjs-progressbar";
import { Provider } from "react-redux";
import AppHelper from "@components/AppHelper";
import ToastContainer from "@components/common/ToastContainer";
import { AppState } from "@redux/reducer";
import { useStore } from '@redux/store';
// single point to import (S)CSS for native Next.js support, all other styles (e.g. from
// node-module) must be loaded there
import "../styles/index.scss";
/**
 * This provides the central entrypoint of the application
 * - providing the redux-store for application-data
 * - activate the AppHelper to observe the user is using the app and authenticated
 * - connect the react-toast to notifications from the redux-store (to provide translated messages to the user)
 * - embed the central app-component with the given page-properties
 */
const FCPApp: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
    // creates the redux-store for storing data
    const store = useStore(pageProps?.initialReduxState as AppState);
    // NextNprogress gives a visual indication of route changes while pages are loading
    // ToastContainer wraps react-toastify to show small alert popups
    return <Provider store={store}>
    <AppHelper />
    <NextNprogress />
    <ToastContainer />
    <Component {...pageProps}/>
  </Provider>;
};
const __Next_Translate__Page__18d1324ec25__ = FCPApp;

    export default __appWithI18n(__Next_Translate__Page__18d1324ec25__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  