import { composeWithDevToolsLogOnlyInProduction } from "@redux-devtools/extension"
import { AxiosRequestConfig } from "axios"
import { useMemo } from "react"
import { applyMiddleware, createStore, Store, StoreEnhancer } from "redux"
import createSagaMiddleware from "redux-saga"

import apiClient from "@api/client"
import { rootReducer, AppState } from "@redux//reducer"
import { selectAuthToken } from "@redux//reducer/auth"
import { rootSaga } from "@redux//saga"
import { hydrateAction } from "@redux/actions/platform"

/**
 * Creates a new Redux store, created server-side but not really used, no sagas run
 */
export const makeStore = (): Store<AppState> => {
  // create the stdChannel (saga middleware)
  const sagaMiddleware = createSagaMiddleware()

  // Dev Tools can be used in production too, but only with logging capability
  const enhancer: StoreEnhancer = composeWithDevToolsLogOnlyInProduction(applyMiddleware(sagaMiddleware))

  const store = createStore(rootReducer, enhancer)

  if (typeof window !== "undefined") {
    // sagas should run only in the client
    sagaMiddleware.run(rootSaga)

    /**
     * Connect store and the apiClient to inject the JWT
     *
     * @todo is there a better place for this?
     */
    apiClient.axios.interceptors.request.use((request: AxiosRequestConfig): AxiosRequestConfig => {
      // these endpoints do not work when an (expired) token is present in the headers
      if (request.url === "/authentication_token" || request.url === "/refresh_token") {
        return request
      }

      const token = selectAuthToken(store.getState())
      if (!token) {
        return request
      }

      request.headers = request.headers || {}
      request.headers.Authorization = `Bearer ${token}`

      return request
    })
  }

  return store
}

/**
 * useMemo ensures the store is not recreated on each page change in the client.
 *
 * @param initialState may be used by pages with getServerSideProps to inject some state
 */
export const useStore = (initialState: AppState): Store<AppState> => {
  const store = useMemo(() => makeStore(), [initialState])
  if (initialState) {
    store.dispatch(hydrateAction(initialState))
  }

  return store
}
