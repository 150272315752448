import { Action } from "redux"

import { IProcess, IUser, IUserEmail as IUserEmail } from "@api/schema"
import { IFormikActions } from "@redux/helper/actions"

/*
Actions for the management of Users for the processmanager.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum UserManagementActionTypes {
  NewUserPassword = "NEW_USER_PASSWORD",
  SendEmailToUsers = "SEND_EMAIL_TO_USERS"
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface IUserManagementAction extends Action {
  type: UserManagementActionTypes
}

export interface INewUserPasswordAction extends IUserManagementAction {
  actions: IFormikActions
  type: UserManagementActionTypes.NewUserPassword
  user: IUser
}

export interface ISendEmailToUsersAction extends IUserManagementAction {
  actions: IFormikActions
  userEmail: IUserEmail
  currentProcess: IProcess
  type: UserManagementActionTypes.SendEmailToUsers
}

export type UserManagementActions =
  // IClearUserListAction
  // |
  INewUserPasswordAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */


export const newUserPasswordAction = (user: IUser, actions: IFormikActions): INewUserPasswordAction => ({
  actions,
  type: UserManagementActionTypes.NewUserPassword,
  user
})

export const sendEmailToUsersAction = (userEmail: IUserEmail, currentProcess: IProcess, actions: IFormikActions): ISendEmailToUsersAction => ({
  actions,
  userEmail,
  currentProcess,
  type: UserManagementActionTypes.SendEmailToUsers
})
