import { AnyAction } from "redux"

import { ISysinfo } from "@api/schema"
import { AppState } from "@redux/reducer"


/*
* Actions especially for general purposes for the application itself.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum PlatformActionTypes {
  ClearStore = "CLEAR_STORE",
  /** for resetting the store with a given data payload */
  Hydrate = "HYDRATE",

  LoadBackendCommit = "LOAD_BACKEND_COMMIT",
  SetBackendCommit = "SET_BACKEND_COMMIT",

  LoadSysinfo = "LOAD_SYS_INFO",
  SetSysinfo = "SET_SYS_INFO",

  SetAuthTTL = "SET_AUTH_TTL",
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

// because those Actions are so general, no specific Actions are defined
// all helper-functions below create Actions of basic type AnyAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */


export const clearStoreAction = (): AnyAction => ({ type: PlatformActionTypes.ClearStore })

/**
 * Action for initialising the state or overwrite parts of an existing state by a given payload
 */
export const hydrateAction = (payload: AppState): AnyAction => ({
  payload,
  type: PlatformActionTypes.Hydrate
})

/**
 * Action to load the current backend commit
 */
export const loadBackendCommitAction = (): AnyAction => ({ type: PlatformActionTypes.LoadBackendCommit })

/**
 * Action to store the loaded backend commit
 */
export const setBackendCommitAction = (hash: string): AnyAction => ({
  hash,
  type: PlatformActionTypes.SetBackendCommit
})


/**
 * Action to load system information about the platform
 */
export const loadSysinfoAction = (): AnyAction => ({ type: PlatformActionTypes.LoadSysinfo })

/**
 * Action to store the loaded system information
 */
export const setSysinfoAction = (sysinfo: ISysinfo): AnyAction => ({
  sysinfo,
  type: PlatformActionTypes.SetSysinfo
})


/**
 * Action to store the remaining time the current authentification of the user has (TTL = time to live)
 */
export const setAuthTTLAction = (ttl: number): AnyAction => ({
  ttl,
  type: PlatformActionTypes.SetAuthTTL
})