import { TranslationQuery } from "next-translate"
import { ToastOptions, TypeOptions } from "react-toastify"
import { Action } from "redux"

import { INotificationContentType } from "@redux/reducer/notifications"

/*
Actions to trigger Notification / Toasts
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum NotificationActionTypes {
  AddNotification = "ADD_NOTIFICATION",
  RemoveNotification = "REMOVE_NOTIFICATION",
}

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface INotificationAction extends Action {
  type: NotificationActionTypes
}

export interface IAddNotificationAction extends INotificationAction {
  content: INotificationContentType
  options: ToastOptions
  params?: TranslationQuery
  type: NotificationActionTypes.AddNotification
}

export interface IRemoveNotificationAction extends INotificationAction {
  id: string
  type: NotificationActionTypes.RemoveNotification
}

export type NotificationActions =
  IAddNotificationAction | IRemoveNotificationAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const addNotificationAction =
  (content: INotificationContentType, type: TypeOptions = "success", options?: ToastOptions, params?: TranslationQuery): IAddNotificationAction => ({
    content,
    options: { type, ...options },
    params,
    type: NotificationActionTypes.AddNotification,
  })

export const removeNotificationAction = (id: string): IRemoveNotificationAction => ({
  id,
  type: NotificationActionTypes.RemoveNotification,
})
