import Cookies from "js-cookie"
import Router from "next/router"
import { ToastOptions } from "react-toastify"
import { Action } from "redux"
import { putWait, withCallback } from "redux-saga-callback"
import { all, call, put, select, takeEvery, takeLatest, takeLeading } from "redux-saga/effects"

import apiClient from "@api/client"
import { IAuthReply, ICredentials, IModel, IPasswordResetRequest, IUser } from "@api/schema"
import { refreshTokenAction, setJwtAction } from "@redux/actions/auth"
import { addNotificationAction } from "@redux/actions/notifications"
import { clearStoreAction } from "@redux/actions/platform"
import { IFormikActions, loadModelAction, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/helper/actions"
import { IRequestState } from "@redux/helper/state"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { AppState } from "@redux/reducer"
import { emptyAuthState, selectCurrentUserId } from "@redux/reducer/auth"
import { selectSingleEntityUsecaseState } from "@redux/reducer/data"
import { INotificationContentType } from "@redux/reducer/notifications"
import { EntityType } from "@redux/reduxTypes"
import { saveAuth } from "@redux/saga/auth"
import { getCurrentUser } from "@redux/saga/currentUser"
import { setStorageItem } from "@services/localStorage"
import { Routes } from "@services/routes"
import { SubmissionError } from "@services/submissionError"
import { AUTH_COOKIE_NAME, AUTH_LOCALSTORAGE_NAME, BASE_URL } from "config"

import { OnboardingType } from "./onboarding"

/** *************************************************************************************************
 * This enum defines the usecases around the "user account".
 * "export" is for tests.
 */
export enum UserAccountUsecases {
  /**
   * load current user
   */
  LoadCurrentUser = "_usecase_load_current_user",
  /**
   * login
   */
  Login = "_usecase_login",
  /**
   * processOnboardingDataAfterLogin - triggers "finalization of onboarding process", chores to do after login
   */
  ProcessOnboardingDataAfterLogin = "_message_process_onboarding_data_after_login",
  /**
   * logout
   */
  Logout = "_usecase_logout",
  /**
   * forgot password
   */
  ForgotPassword = "_usecase_forgot_password",
}

// *************************************************************************************************
// #region user account specific definitions

interface IUserAccountAction extends Action {
  type: UserAccountUsecases
}

export function* userAccountWatcherSaga(): any {
  yield all([
    takeLatest(UserAccountUsecases.Login, withCallback(loginSaga)),
    takeEvery(UserAccountUsecases.Logout, logoutSaga), // NOTE originally in saga/auth, and missing the withCallback hook
    takeEvery(UserAccountUsecases.ForgotPassword, forgotPasswordSaga), // NOTE originally in saga/auth, and missing the withCallback hook

    yield takeLeading(UserAccountUsecases.LoadCurrentUser, withCallback(loadCurrentUserSaga))
    // yield takeLatest(CurrentUserActionTypes.ChangeEmail, withCallback(changeEmailSaga))
    // yield takeLatest(CurrentUserActionTypes.ChangePassword, withCallback(changePasswordSaga))
    // yield takeLatest(CurrentUserActionTypes.DeleteAccount, withCallback(deleteAccountSaga))
  ])
}

// #endregion


// *************************************************************************************************
// #region login

interface ILoginAction extends IUserAccountAction {
  callbackActions: IFormikActions
  credentials: ICredentials
  type: UserAccountUsecases.Login
}

export const loginAction = (credentials: ICredentials, callbackActions: IFormikActions): ILoginAction => ({
  callbackActions,
  credentials,
  type: UserAccountUsecases.Login,
})

export interface IProcessOnboardingDataAfterLoginAction extends IUserAccountAction {
  type: UserAccountUsecases.ProcessOnboardingDataAfterLogin
  user: IUser
  onSuccess: OnOnboardingDataAfterLoginSuccessCallback
  onError: OnOnboardingDataAfterLoginErrorCallback
}

export type OnOnboardingDataAfterLoginSuccessCallback = (onboardingType: OnboardingType, createdObject: IModel) => void
export type OnOnboardingDataAfterLoginErrorCallback = (error: string) => void

export const processOnboardingDataAfterLoginAction = (
  user: IUser,
  onSuccess: OnOnboardingDataAfterLoginSuccessCallback,
  onError: OnOnboardingDataAfterLoginErrorCallback): IProcessOnboardingDataAfterLoginAction => ({
    type: UserAccountUsecases.ProcessOnboardingDataAfterLogin,
    user,
    onSuccess,
    onError
  })

/**
 * Returns an (single entity) IRequestState that contains the status of the request.
 * This special selector exists b/c pages that use loginAction must not know the usecaseKey
 * that is internally used (UserAccountUsecases.Login) - it's a "black box".
 */
export const selectLoginUsecaseState = (state: AppState): IRequestState =>
  selectSingleEntityUsecaseState(state, EntityType.User, UserAccountUsecases.Login) // usecaseKey matches the one in loginAction/Saga

/**
 * exported for the tests
 */
export function* loginSaga(action: ILoginAction): Generator<any, void, any> {
  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type // UserAccountUsecases.Login

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.User, usecaseKey))
    const auth: IAuthReply = yield call(apiClient.requestAuthToken, action.credentials)
    yield call(saveAuth, auth)
    // yield put(usecaseRequestSuccessAction(ScopeTypes.UserLogin, auth)) // NOTE formerly we sent the auth object to the scopedRequestReducer ... was it required?

    // notify other tabs to re-check their auth cookie to also login
    yield call(setStorageItem, AUTH_LOCALSTORAGE_NAME, Date.now().toString())

    // loading the current user
    const currentUser: IUser = yield call(getCurrentUser)
    if (!currentUser) {
      throw new Error("user.currentUserNotLoaded")
    }

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.User, usecaseKey, currentUser))

    // trigger follow-up activities, if onSuccess is defined
    if (onSuccess) {
      // return loaded currentUser to the calling onSuccess
      yield call(onSuccess, currentUser)
    }
    // trigger the refresh mechanism
    yield put(refreshTokenAction())
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.User, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
  }
}

// #endregion


// *************************************************************************************************
// #region logout

interface ILogoutAction extends IUserAccountAction {
  message?: INotificationContentType
  options?: ToastOptions
  redirect?: string
  type: UserAccountUsecases.Logout
}

export const logoutAction = (message?: INotificationContentType, redirect?: string, options?: ToastOptions): ILogoutAction => ({
  message,
  options,
  redirect,
  type: UserAccountUsecases.Logout,
})

// NOTE selector not needed since we do not call any reducer/state-updating actions
// /**
//  * Returns an (single entity) IRequestState that contains the status of the request.
//  * This special selector exists b/c pages that use logoutAction must not know the usecaseKey
//  * that is internally used (UserAccountUsecases.Logout) - it's a "black box".
//  */
// export const selectLogoutUsecaseState = (state: AppState): IRequestState =>
//   selectSingleEntityUsecaseState(state, EntityType.User, UserAccountUsecases.Logout) // usecaseKey matches the one in logoutAction/Saga

/**
 * exported for the tests
 */
export function* logoutSaga(action: ILogoutAction): Generator<any, void, any> {
  // NOTE usecaseKey not needed since we do not call any reducer/state-updating actions
  // // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  // const usecaseKey = action.type // UserAccountUsecases.Logout

  // redirect before the actual logout, to prevent a flash of the 403 page
  yield call(Router.push, action.redirect || Routes.Marketplace)

  // eslint-disable-next-line @typescript-eslint/unbound-method
  yield call(Cookies.remove, AUTH_COOKIE_NAME)

  // Reset the complete store, we don't want anything loaded with higher privileges remaining
  yield put(clearStoreAction())

  // but set auth.initialized to true again so withAuth() works
  yield put(setJwtAction(emptyAuthState))

  // notify other tabs to re-check their auth cookie to also logout
  yield call(setStorageItem, AUTH_LOCALSTORAGE_NAME, Date.now().toString())

  // only after the store was cleared add new state
  yield put(addNotificationAction(action.message || "message.auth.logout", "info", action.options))
}

// #endregion


// *************************************************************************************************
// #region forgot password

/**
 * Action to trigger a process to renew a forgotten password
 */
interface IForgotPasswordAction extends IUserAccountAction {
  callbackActions: IFormikActions
  data: IPasswordResetRequest
  type: UserAccountUsecases.ForgotPassword
}

export const forgotPasswordAction = (data: IPasswordResetRequest, callbackActions: IFormikActions): IForgotPasswordAction => ({
  callbackActions,
  data,
  type: UserAccountUsecases.ForgotPassword,
})

/**
 * Returns an (single entity) IRequestState that contains the status of the request.
 * This special selector exists b/c pages that use forgotPasswordAction must not know the usecaseKey
 * that is internally used (UserAccountUsecases.ForgotPassword) - it's a "black box".
 */
export const selectForgotPasswordUsecaseState = (state: AppState): IRequestState =>
  selectSingleEntityUsecaseState(state, EntityType.User, UserAccountUsecases.ForgotPassword) // usecaseKey matches the one in forgotPasswordAction/Saga

function* forgotPasswordSaga(action: IForgotPasswordAction) {
  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type // UserAccountUsecases.ForgotPassword

  try {
    // the API uses {{param}} as placeholder while Next uses [param]:
    const route = Routes.ConfirmPasswordReset.replace(/\[/g, "{{").replace(/\]/g, "}}")
    action.data.validationUrl = BASE_URL + route

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.User, usecaseKey))
    const res: boolean = yield call(apiClient.forgotPassword, action.data)

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.User, usecaseKey, null)) // NOTE/todo returning null; with old requestReducer it was `res`
    if (onSuccess) {
      yield call(onSuccess, res)
    }

    return res
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.User, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

// #endregion


// *************************************************************************************************
// #region load current user object


interface ILoadCurrentUserAction extends IUserAccountAction {
  type: UserAccountUsecases.LoadCurrentUser
}

export const loadCurrentUserAction = (): ILoadCurrentUserAction => ({
  type: UserAccountUsecases.LoadCurrentUser,
})

/**
 * Returns an (single entity) IRequestState that contains the status of the request.
 * This special selector exists b/c pages that use loadCurrentUserAction must not know the usecaseKey
 * that is internally used (UserAccountUsecases.LoadCurrentUser) - it's a "black box".
 */
export const selectLoadingCurrentUserUsecaseState = (state: AppState): IRequestState =>
  selectSingleEntityUsecaseState(state, EntityType.User, UserAccountUsecases.LoadCurrentUser) // usecaseKey matches the one in loadCurrentUserAction/Saga


/**
 * saga to load the current logged in user, identified by its ID
 * NOTE/todo: this saga is not a real saga but more a function that fetches an id and calls another saga.
 * Maybe we'll refactor it accordingly
 */
function* loadCurrentUserSaga(action: ILoadCurrentUserAction) {
  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type // UserAccountUsecases.LoadCurrentUser

  const id: number = yield select(selectCurrentUserId)
  if (!id) {
    // NOTE/todo normally we would like to call a *RequestRunningAction(type, usecase) BEFORE calling it again with an error
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.User, usecaseKey, "failure.notLoggedIn"))
    return null
  }

  const user: IUser = yield putWait(loadModelAction(EntityType.User, id, usecaseKey))

  return user

}

// #endregion