import { Action } from "redux"

import { IPasswordReset, IValidation } from "@api/schema"
import { IFormikActions } from "@redux/helper/actions"

/*
Actions for the validation of a user account
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum ValidationActionTypes {
  ConfirmAccount = "CONFIRM_ACCOUNT",
  ConfirmChangeEmail = "CONFIRM_EMAIL",
  ResetPassword = "RESET_PASSWORD",
  ResetValidationResult = "RESET_VALIDATION_RESULT",
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface IValidationAction extends Action {
  type: ValidationActionTypes
}

export interface IConfirmAccountAction extends IValidationAction {
  actions: IFormikActions
  data: IValidation
  type: ValidationActionTypes.ConfirmAccount
}

export interface IConfirmChangeEmailAction extends IValidationAction {
  actions: IFormikActions
  data: IValidation
  type: ValidationActionTypes.ConfirmChangeEmail
}

export interface IResetPasswordAction extends IValidationAction {
  actions: IFormikActions
  data: IPasswordReset
  type: ValidationActionTypes.ResetPassword
}

export interface IResetValidationResultAction extends IValidationAction {
  type: ValidationActionTypes.ResetValidationResult
}

export type ValidationActions =
  IConfirmAccountAction
  | IConfirmChangeEmailAction
  | IResetPasswordAction
  | IResetValidationResultAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const confirmAccountAction = (data: IValidation, actions: IFormikActions): IConfirmAccountAction => ({
  actions,
  data,
  type: ValidationActionTypes.ConfirmAccount,
})

export const confirmChangeEmailAction = (data: IValidation, actions: IFormikActions): IConfirmChangeEmailAction => ({
  actions,
  data,
  type: ValidationActionTypes.ConfirmChangeEmail,
})

export const resetPasswordAction =
  (data: IPasswordReset, actions: IFormikActions): IResetPasswordAction => ({
    actions,
    data,
    type: ValidationActionTypes.ResetPassword,
  })

export const resetValidationResultAction = (): IResetValidationResultAction => ({
  type: ValidationActionTypes.ResetValidationResult,
})
