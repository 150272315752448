

import { entityEndpointList } from "@api/client"
import { IModel, INumericIdentifierModel, IRI, ISlugAndNumericIdentifierModel } from "@api/schema"
import { EntityType } from "@redux/reduxTypes"

import { FCP_API_ENTRYPOINT } from "../../config"

/**
 * @returns true if the platform is running in a test environment (where certain stuff is not available)
 */
export const platformIsInTestEnvironment = (): boolean => !process.env.NODE_ENV || process.env.NODE_ENV === 'test'

/**
 * @returns true if the platform is running in a development environment
 */
export const platformIsInDevEnvironment = (): boolean => process.env.NODE_ENV === 'development'

/**
 * @returns true if the platform is running in a production environment
 */
export const platformIsInProductionEnvironment = (): boolean => process.env.NODE_ENV === 'production'

/**
 * Types of unhandled unexpected errors
 */
export declare type UnhandledUnexpectedErrorType = 'server' | 'i18n' | 'fetching-url'

/**
 * Log unhandled unexpected errors, e.g. on console or via Sentry.
 *
 * @todo add Sentry handling, including a setting/environment flag
 */
export const logUnhandledUnexpectedError = (errorMsg: string, type: UnhandledUnexpectedErrorType): void => {
  // eslint-disable-next-line no-console
  console.log("Unhandled unexpected error of type '" + type + "': " + errorMsg)
}

/**
 * @returns all elements that exists in both arrays
 */
export const arrayIntersect = (a: any[], b: any[]): any[] => a.filter(ele => b.includes(ele))

/**
 * @returns true, if b is part of a, or if b is an array and has intersections with a
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasMatches = (a: any[], b: any): boolean =>
  Array.isArray(b) ? arrayIntersect(a, b).length > 0 : a.includes(b)

/**
 * This method is only useful as parameter for array.filter.
 * The method effectively checks whether the given value does not exist again before the given index.
 * I.e. it returns true if the given value-index-pair is the value's first occurrence in the array.
 *
 * May be used to keep the first occurrence of every value, and filter out following duplicates.
 * The method signature is exactly what is required for the callback in Array.filter.
 * Usage: `myArray.filter(isFirstOccurrenceOfValueInArray)` to create an array with unique values.
 *
 * @see https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
 *
 * @param value an entry of the array (for which we want to check if it's the first element with this value in the array)
 * @param index the index of the value in the array
 * @param array the array
 * @returns whether this value is the first one in this array
 */
export const isFirstOccurrenceOfValueInArray = <T>(value: T, index: number, array: T[]): boolean =>
  array.indexOf(value) === index

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 * binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes.toString() + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)


  return bytes.toFixed(dp) + ' ' + units[u]
}

/**
 * The FCP backend allows to download private files (project PDFs, team uploads etc.) via
 * special endpoints which check the authorization and then stream the file.
 * We cannot use GET for those endpoints as we don't want to transport the JWT in the query
 * as it may show up in log files.
 * We also don't want to use XHR to trigger the download as this would load the response into
 * the browser memory and we would need to then provide it as BLOB download.
 * So we use a hidden/temporary form that we submit automatically, this way the browser requests
 * the given file URL with a POST request and the JWT in the body.
 */
export const triggerDownload = (url: string, token: string, newPage = true): void => {
  // check, if the application element is running on the server or in the browser currently => window is just in the browser defined
  if (typeof window !== undefined) {
    const form = document.createElement('form')
    form.method = "POST"
    form.action = url
    if (newPage) {
      form.target = "_blank"
    }

    const input = document.createElement('input')
    input.name = "bearer"
    input.type = "hidden"
    input.value = token
    form.appendChild(input)

    document.body.appendChild(form)
    form.submit()
    form.parentNode.removeChild(form)
  }
}

/**
 * checks, if a given IModel is loaded with detailed results
 *
 * @param entity the entity to check
 * @returns true, if detailed
 *
 * NOTE: does not work, when IProject is fetched within a collection, e.g. within a list of currentUser or similar -> @todo FCP-1062
 */
export const isDetailedObject = (entity: IModel): boolean => !!entity && entity.detailResult

// #region helper on IDs or IRIs calculation/extraction of IModel types

/**
 * Test, if the object is empty, null or undefined
 *
 * @param inputObject
 * @returns true, if the object is empty, null or undefined
 */
export const isEmptyNullOrUndefinedObject = (inputObject: object): boolean => {
  return inputObject === undefined
    || inputObject === null
    || (
      inputObject &&
      Object.keys(inputObject).length === 0 &&
      inputObject.constructor === Object
    )
}
/**
 * Returns the IRI ("@id") of an IModel or a string depending on the type of the given parameter.
 * Should be used when an entity property is of type "string | IModel" to have a shortcut to get the
 * IRI. If the type is string it is expected that it contains the IRI ('@id').
 *
 * @param iriElement represents an IRI or an IModel
 * @returns the iriElement as string if it is a string (which should be also an @id) or the "@id" of the IModel
 */
export const iriFromIModelOrIRI = (iriElement: IModel | IRI): IRI => {
  if (typeof iriElement === "string") {
    // calculate iristub by searching for slash after position 1, assuming slash at position 0
    const iristub = iriElement.substring(0, iriElement.indexOf("/", 1))
    // check, if the IRIsub is a known endpoint
    if (Object.values(entityEndpointList).find(iri => iri.url === iristub)) {
      return iriElement
    }
    else {
      // if the IRIstub is not part of known endpoints
      return undefined
    }
  } else {
    return iriElement?.["@id"]
  }
}

/**
 * Returns the IRI ("@id") for an IModel of given EntityType with the given id.
 *
 * @param entityType The type of the IModel
 * @param id The id of the IModel
 * @returns the iri/"@id" as string
 */
export const iriFromEntityTypeAndId = (entityType: EntityType, id: number): IRI =>
  `${entityEndpointList[entityType].url}/${id}`

/**
 * returns the id of an entity, given as IRI-string (["@id"], e.g. /discussions/117) or an IModel
 *
 * In following situation the function will return undefined:
 * -> input has no number
 * -> input is not identified as an "@id" (e.g. /users/7)
 * -> input is neither an "@id" nor an object with an id
 */
export const idFromIModelOrIRI = (entity: IRI | INumericIdentifierModel): number => {

  if (!entity) {
    return undefined
  }

  if (typeof entity === "string") {
    const lastIndexOfSlash = entity.lastIndexOf("/")
    if (lastIndexOfSlash !== -1) {
      // the function will return the id from the "@id"(string)
      return Number(entity.substring(entity.lastIndexOf("/") + 1))
    }

    return undefined

    // use Object.hasOwn instead of entity.hasOwnProperty, because it is more robust,
    // which means: if created an object whose prototype is not Object.prototype,it will not have the hasOwnProperty method,
  }

  if (typeof entity === "object"
    && Object.hasOwn(entity, 'id') // NOTE this may nor be necessary, since INumericIdentifierModel has a id prop by definition
  ) {
    return entity.id
  }
}

/**
 * Returns the slug attribute if existing, otherwise the id
 *
 * @param slugAndNumericIdentifiable The slugAndNumericIdentifiable with slug or ID, or an IRI
 * @returns slug or id
 */
export const slugOrIdFromISlugAndNumericIdentifierModelOrIRI = (slugAndNumericIdentifiable: IRI | ISlugAndNumericIdentifierModel): string => {
  if (slugAndNumericIdentifiable
    && typeof slugAndNumericIdentifiable === "object"
    && Object.hasOwn(slugAndNumericIdentifiable, 'slug') // NOTE this may nor be necessary, since ISlugAndNumericIdentifierModel has a slug prop by definition
    && !!slugAndNumericIdentifiable.slug // NOTE we must test for non-emptyness, since we cannot return undefined before the alternative path (idFromIModelOrIRI) has been went
  ) {
    return slugAndNumericIdentifiable.slug
  }

  return idFromIModelOrIRI(slugAndNumericIdentifiable)?.toString()
}

/**
 * Returns the EntityType of a given IModel
 *
 * @param entity an IModel with valid "@id" or a plain "@id"/IRI
 * @returns the EntityType of the given entity
 */
export const entityTypeFromIModelOrIRI = (entity: IModel | IRI): EntityType => {
  // if no entity is given
  if (!entity) {
    return null
  }

  if (typeof entity === "string") {
    return entityTypeFromIRI(entity)
  } else {
    if (entity["@id"]) {
      return entityTypeFromIRI(entity["@id"])
    }
    else {
      // if there is no "@id"
      return undefined
    }
  }
}

/**
 * Returns if a given IModel or IRI correlates to a given EntityType
 *
 * @param entity an IModel with valid "@id" or a "@id"/IRI
 * @param entityType the EntityType the entity should match
 * @returns true, if the given entity has the given EntityType
 */
export const isOfEntityType = (entity: IModel | IRI, entityType: EntityType): boolean => {
  return entityTypeFromIModelOrIRI(entity) === entityType
}


/**
 * Returns the corresponding EntityType on a given IRI/"@id"
 *
 * @param iri a valid IRI (pattern: /entity_endpoint_url/8) from an IModel[@id] to identify a single entity
 * @returns the corresponding EntityType
 */
const entityTypeFromIRI = (iri: IRI): EntityType => {
  if (!iri) {
    return null
  }

  // first character must be a /
  if (!iri.startsWith("/")) {
    return null
  }

  const indexOfSecondSlash = iri.indexOf("/", 2) // start searching on position after first character
  // no second slash found? -> no IRI!
  if (indexOfSecondSlash === -1) {
    return null
  }

  // calculate the isolated URL
  const urlFromIri = iri.substring(0, indexOfSecondSlash)

  // find the key of the corresponding entry in the entityEndpointList -> this is the searched EntityType-value!
  let entityType: string
  Object.entries(entityEndpointList).forEach(endpoint => {
    if (endpoint[1].url === urlFromIri) {
      entityType = endpoint[0]
      return
    }
  })

  return entityType as EntityType
}

// #endregion


/**
 * Converts the timespan given in (milli)seconds to human readable time format: [hh:]mm:ss
 */
export const humanTime = (duration: number, isMilliseconds = true): string => {
  const totalSeconds = isMilliseconds ? Math.floor(duration / 1000) : duration
  const hours = Math.floor(totalSeconds / 60 / 60)
  const minutes = Math.floor((totalSeconds - (hours * 60 * 60)) / 60)
  const seconds = totalSeconds % 60

  const time = minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0")

  return hours > 0
    ? hours.toString() + ":" + time
    : time
}

// @todo this is for old APIs that dont prefix their our themselves,
// to be removed when no old API is used anymore, search for all usages of this function
export const prefixApiUrl = (path: string): string =>
  path.startsWith('https://') || path.startsWith('http://') ? path : FCP_API_ENTRYPOINT + path

/**
 * @returns a random integer between min and max
 */
export const randomIntFromInterval = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min)


/**
 * converts a given string to an integer
 *
 * @param value a string to be converted to an integer
 * @returns converted number, or null, if conversion was not possible
 */
export const stringToInt = (value: string): number =>
  /^\d+$/.exec(value) ? parseInt(value, 10) : null

/**
 * checks if a conversation to number is possible
 *
 * @param value a string to be checked
 * @returns true, if the given string could be converted to an int
 */
export const isInt = (value: string): boolean =>
  stringToInt(value) !== null

/**
 * Reduces an array of any objects to an string array, just of ids of those objects
 *
 * @param list An array of objects with an id-attribute.
 * @returns an array of ids only
 */
export const getIDs = (list: any[]): string[] => list.map((e) => e.id as string)

/**
 * Checks if a given ID is included in the list of the items given in the array
 *
 * @param id id of an entity
 * @param listOfEntities array of entities
 * @returns true, if at leas one of the given entities has the given id
 */
export const idExistsInEntities = (id: number, listOfEntities: INumericIdentifierModel[]): boolean => {
  if (!id || !listOfEntities) {
    return false
  }
  return listOfEntities.find(entity => entity.id === id) !== undefined
}

/**
 * Reduces an array of any objects to a number array, just of ids of those objects
 *
 * @param list An array of objects with an id-attribute.
 * @returns an array of ids only
 */
export const getIDsByNumbericIdentifierModel = (list: INumericIdentifierModel[]): number[] => list ? list.map((e) => e.id) : []

/**
 * Checks if the URI-Hash of the users window-URL matches the given hashId.
 * This function is used when checking, if the user "jumps" to an element inside a page,
 * e.g. to open it
 *
 * @param hashId the hash-id to be checked
 * @returns true, if the given hashId matches the uriHash of the current URL
 */
export const uriHashMatchesId = (hashId: string): boolean => {
  if (typeof window === "undefined") return false
  if (!window.location.hash) return false
  return window.location.hash === `#${hashId}`
}

/**
 * Removes all characters after # from an URL
 *
 * @param url
 * @returns url without hash
 */
export const urlWithoutHash = (url: string): string => {
  if (url.indexOf("#") >= 0) {
    return url.substring(0, url.indexOf("#"))
  } else {
    return url
  }
}

/**
 * converts an IRI or an IModel to a string useable as id of an HTML element
 *
 * @param entity an entity as IModel or IRI
 * @returns a converted and useable html id based on the entity IRI
 */
export const iriAsHtmlId = (entity: IModel | IRI): string => {
  if (!entity) {
    return undefined
  }

  return stringAsHtmlId(iriFromIModelOrIRI(entity) as string)
}

/**
 * converts an string with potentially special characters to a string useable as id of an HTML element
 *
 * @param s a string
 * @returns a converted and useable html id based on the entity IRI
 */
export const stringAsHtmlId = (s: string): string => {
  if (!s) {
    return undefined
  }

  return s?.replaceAll(/\W/g, "_")
}


// #region Timeline handling

/**
 * Interface to define steps/phases on a timeline
 */
export interface TimelinePhase {
  /** day when a phase starts */
  startDate: string
  /** number of the phase, to be able to compare (e.g. if (phase >= 2)... */
  phase: number
}


/**
 * calculates from a given Timeline the latest phase in which todays date is lying in
 *
 * @param timeline an array of timeline
 * @returns
 */
export const getLatestTimelinePhase = (timeline: TimelinePhase[]): TimelinePhase => {
  let latestDate: TimelinePhase = { startDate: "0", phase: null }

  // find out this date in the timeline, that is the newest/latest, but is in the past compared to today
  timeline.forEach(step => {
    const today = new Date()
    const dateToCheck = new Date(step.startDate)
    if (dateToCheck > new Date(latestDate.startDate) && today >= dateToCheck) {
      latestDate = step
    }
  })

  return latestDate
}
// #endregion
