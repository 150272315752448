// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from "@sentry/tracing"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || (process.env.NODE_ENV === 'production' ? 'prod' : 'dev')
const SENTRY_TRACE_SAMPLE_RATE = process.env.SENTRY_TRACE_SAMPLE_RATE || 0 // see below

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE, // 1.0 = 100% of all sessions are traced and send to sentry, 0.0 = 0% of all sessions are traced
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // to solve the CORS-preflight-problems, that report "Unauthorized header sentry-trace"
  integrations: [new BrowserTracing({ tracingOrigins: [process.env.BASE_URL] })],

  environment: SENTRY_ENVIRONMENT,

  debug: false // process.env.NODE_ENV !== 'production'
})
