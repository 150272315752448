// use JS instead of TS because this file is used by the non-transpiled server.js; used by i18n.js

/** technically supported locales -> new locale must be mapped to date-fns in formatDate.js->localeToDateFns  */
const SUPPORTED_LOCALES = ["de", "en"]

/**
 * base locale of the platform - the basis from which translations into other languages are derived
 * to change the default locale of the customer/what the user sees by default: @see DEFAULT_LOCALE
 *
 * @type {string}
 */
const PLATFORM_BASE_LOCALE = "de"

/**
 * default locale to be presented to the user, it may differ from the platform default
 *
 * @type {string}
 */
const DEFAULT_LOCALE = "de"

/** @type {string} */
const LOCALES_FOLDER_NAME = './locales/'
/** @type {string} */
const LOCALES_IMPORT_PATH = '@locales/'
/** @type {string} */
const CUSTOM_LOCALES_FOLDER_NAME = LOCALES_FOLDER_NAME + 'custom-locales/'
/** @type {string} */
const CUSTOM_LOCALES_IMPORT_PATH = LOCALES_IMPORT_PATH + 'custom-locales/'

/** list of languages, where SDG files are put into the folder @see: sdgPathCreatorFunction */
const SDG_EXISTS_FOR_LANGUAGES = ["de", "en"]
/**
 * if chosen language is not part of the SDG_EXISTS_FOR_LANGUAGES
 * this language is used as fallback to show the SDGs
 */
const SDG_FALLBACK_LANGUAGE = "en"

/**
 * platform specific list of entries that should be ignored when translating
 * in the translate.ts script
 *
 * add customer specific DO_NOT_TRANSLATE_LIST in custom-locales/locales-config.ts
 *
 * @type {(string | RegExp)[]}
 */
const PLATFORM_SPECIFIC_DO_NOT_TRANSLATE_LIST = [
  "Digitale Projektfabrik",
  /Projektfabrik[a-z]*/g, // regex to replace all appearances/variants of "Projektfabrik" extended by following characters (e.g. "Projektfabriken"); the g at the end makes it global, @see: https://deepscan.io/docs/rules/bad-replace-all-arg
  "futureprojects",
  "GmbH",
  "Dresden",
]

/**
 * parameter markers within the translation values:
 * start marker
 *
 * @type {string}
 */
const LOCALES_PARAM_START_MARKER = "{{"

/**
 * parameter markers within the translation values:
 * end marker
 *
 * @type {string}
 */
const LOCALES_PARAM_END_MARKER = "}}"


module.exports = {
  SUPPORTED_LOCALES,
  PLATFORM_BASE_LOCALE,
  DEFAULT_LOCALE,
  LOCALES_FOLDER_NAME,
  LOCALES_IMPORT_PATH,
  CUSTOM_LOCALES_FOLDER_NAME,
  CUSTOM_LOCALES_IMPORT_PATH,
  PLATFORM_SPECIFIC_DO_NOT_TRANSLATE_LIST,
  LOCALES_PARAM_START_MARKER,
  LOCALES_PARAM_END_MARKER,
  SDG_EXISTS_FOR_LANGUAGES,
  SDG_FALLBACK_LANGUAGE
}