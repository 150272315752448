
import {
  ChallengeTransitionState,
  IActionResult,
  // IAttachmentDefinition,
  IAuthReply,
  IChallenge,
  // IChallengeConcretization,
  IContactEmail,
  ICredentials,
  IEmailChange,
  IFeedbackInvitation,
  IFeedbackPost,
  IFundApplication,
  IHydraCollection,
  INewPasswordRequest,
  INumericIdentifierModel,
  IPasswordChange,
  IPasswordReset,
  IPasswordResetRequest,
  IProcess,
  IProject,
  IProjectReport,
  IProposal,
  IProposalAttachment,
  IRIstub,
  IStatistics,
  ISysinfo,
  ITeamEmail,
  ITeamUpload,
  IUser,
  IUserEmail,
  IValidation,
  ProjectState,
  ProposalTransitionState
} from "@api/schema"
import { EntityType, StatisticsType, UploadType } from "@redux/reduxTypes"
import { HydraClient } from "@services/hydraClient"
import { entityTypeFromIModelOrIRI, iriFromIModelOrIRI } from "@services/util"
import { FCP_API_ENTRYPOINT } from "config"

import { IUserWriteDTO } from "./schema-dto"


// #region types and interface definitions

/**
 * this data defines an endpoint
 */
export interface Endpoint {
  /**
   * url must not end with a slash
   */
  url: IRIstub
}

/**
 * an endpoint for a specific entity is defined as
 * EntityType => Endpoint
 */
export type EntityEndpointList = {
  [key in EntityType]: Endpoint
}

/**
 * an endpoint for a specific statistic is defined as
 * StatisticsType => Endpoint
 */
export type StatisticsEndpointList = {
  [key in StatisticsType]: Endpoint
}

/**
 * an endpoint for a upload is defined as
 * UploadType => Endpoint
 */
export type UploadEndpointList = {
  [key in UploadType]: Endpoint
}

// #endregion

// #region endpoint definitions

/**
 * This list contains all entity-specific endpoints
 * to be used in the generic functions to create, update, delete, load single elementes and collections
 * of an entity.
 *
 * Every url is identical with the central part of an "@id" of an IModel, called IRI.
 * So with this list it is also possible to calculate the EntityType from an IRI, done in
 * entitytypeFromIri()
 */
export const entityEndpointList: EntityEndpointList = {
  [EntityType.AttachmentDefinition]: {
    url: "/attachment_definitions",
  },
  [EntityType.Category]: {
    url: "/categories",
  },
  [EntityType.Challenge]: {
    url: "/challenges",
  },
  [EntityType.ChallengeConcretization]: {
    url: "/challenge_concretizations",
  },
  [EntityType.Discussion]: {
    url: "/discussions",
  },
  [EntityType.FeedbackInvitation]: {
    url: "/feedback_invitations",
  },
  [EntityType.FeedbackPost]: {
    url: "/feedback_posts",
  },
  [EntityType.Proposal]: {
    url: "/proposals",
  },
  [EntityType.ProposalAttachment]: {
    url: "/proposal_attachments",
  },
  [EntityType.Process]: {
    url: "/processes",
  },
  [EntityType.Project]: {
    url: "/projects",
  },
  [EntityType.ProjectFollowership]: {
    url: "/project_followerships",
  },
  [EntityType.ProjectMembership]: {
    url: "/project_memberships",
  },
  [EntityType.Sdgs]: {
    url: "/sdgs",
  },
  [EntityType.TeamUpload]: {
    url: "/team_uploads",
  },
  [EntityType.User]: {
    url: "/users",
  },
}


/**
 * This list contains all statistic-specific endpoints
 * to be used in the generic functions to load them.
 */
const statisticsEndpointList: StatisticsEndpointList = {
  [StatisticsType.Challenges]: {
    url: "/challenges/statistics",
  },
  [StatisticsType.Platform]: {
    url: "/statistics",
  },
  [StatisticsType.Projects]: {
    url: "/projects/statistics",
  },
  [StatisticsType.User]: {
    url: "/users/statistics",
  },
}


/**
 * This list contains most upload-specific endpoints
 * to be used in the generic functions to upload (create), update or delete a file.
 *
 * Because a file is usually bound to a specific entity that is identified by its id
 * the URL usually uses [id] as placeholder to be replaced by the real id by the upload function.
 */
const uploadEndpointList: UploadEndpointList = {
  [UploadType.ConcretizationImage]: {
    url: "/challenge_concretizations/[id]/image",
  },
  [UploadType.ChallengeLogo]: {
    url: "/challenges/[id]/logo",
  },
  [UploadType.ProcessLogo]: {
    url: "/processes/[id]/logo",
  },
  [UploadType.ProjectPicture]: {
    url: "/projects/[id]/picture",
  },
  [UploadType.ProjectVisualization]: {
    url: "/projects/[id]/visualization",
  },
  [UploadType.UserPicture]: {
    url: "/users/[id]/picture",
  }
}
// #endregion

/**
 * This class provides functions for the communication with the backend-API
 * based on HydraClient, that uses Axios for fetching and sending data to servers.
 * All relevant endpoints are addressed here.
 */

export class ProjektfabrikClient extends HydraClient {

  // #region generalEntityFunctions

  /**
   * General function to load one Entity from the backend api by id.
   * Entity-specific endpoint must be defined in the entityEndpointList.
   *
   * @param entityType entitytype that should be loaded
   * @param id id of the entity that should be loaded
   * @returns the entity
   */
  public loadSingleEntity = <T extends INumericIdentifierModel>(entityType: EntityType, id: number): Promise<T> => {
    // get the endpoint definition from the entityEndpointList
    const endpoint = entityEndpointList[entityType]

    // start the request and return results
    return this.get(endpoint.url + "/" + id.toString()) as Promise<T>
  }


  /**
   * General function to load one Entity as Stub (!) from the backend api by slug.
   * Entity-specific endpoint must be defined in the entityEndpointList.
   *
   * ATTENTION:
   * loadSingleEntityStubBySlug may return an entity with detailResult === false, because
   * the API does not allow a single-load by slug", but only collection load "by slug as criteria"
   * Therefor this function returns the first element of a collection, that may have detailResult === false.
   * The caller must check, if detailResult === false and trigger a loadSingleEntity with the return entity ID
   * to get detailed results
   *
   * @param entityType entitytype that should be loaded
   * @param slug slug of the entity that should be loaded
   * @returns the entity
   */
  public loadSingleEntityStubBySlug = <T extends INumericIdentifierModel>(entityType: EntityType, slug: string): Promise<T> => {
    return this.loadEntityCollection<T>(entityType, { slug }).then((entities) => entities["hydra:member"].shift())
  }


  /**
   * General function to load the first page of a collection of entities from the backend api.
   * Entity-specific endpoint must be defined in the entityEndpointList.
   *
   * The result returns the next page url in the IHydraCollection: hydra:nextPage
   * This url should be passed to the get-function to fetch its content.
   *
   * @todo: müßte diese Funktion korrekterweise heißen loadEntityCollectionFirstPage? Der Abruf von Folge-Pages erfolgt direkt über die .get-Funktion
   *
   * @param entityType entitytype that should be loaded
   * @param query list of criteria for the entities that should be loaded
   * @returns a collection of entities
   */
  public loadEntityCollection = <T extends INumericIdentifierModel>(entityType: EntityType, query: Record<string, unknown> = {}): Promise<IHydraCollection<T>> => {
    // get the endpoint definition from the entityEndpointList
    const endpoint = entityEndpointList[entityType]

    // start the request and return results
    return this.get(endpoint.url, query) as Promise<IHydraCollection<T>>
  }

  /**
   * General function to create an entity of a given type via the backend api.
   *
   * @param entity the entity to be updated
   * @returns the updated entity
   */
  public createEntity = <T extends INumericIdentifierModel>(entityType: EntityType, entity: INumericIdentifierModel): Promise<T> => {
    // special use cases for upload transaction (calls this.upload instead of this.post)
    switch (entityType) {
      // ProposalAttachment is a file and properties, so it has to be handled as upload
      case EntityType.ProposalAttachment:
        return this.createProposalAttachment(entity) as Promise<T>
      // TeamUpload is a file and properties, so it has to be handled as upload
      case EntityType.TeamUpload:
        return this.createTeamUpload(entity) as Promise<T>
    }

    const endpoint = entityEndpointList[entityType]

    // start the request
    return this.post(endpoint.url, entity) as Promise<T>
  }


  /**
   * General function to update a given entity via the backend api.
   * Entity-specific endpoint is encoded in the IRI/@id of every entity
   *
   * @param entity the entity to be updated
   * @returns the updated entity
   */
  public updateEntity = <T extends INumericIdentifierModel>(entity: INumericIdentifierModel): Promise<T> => {
    // special use cases
    switch (entityTypeFromIModelOrIRI(entity)) {
      // ProposalAttachment is a file and properties, so it has to be handled as upload
      case EntityType.ProposalAttachment:
        return this.updateProposalAttachment(entity) as Promise<T>
      // TeamUpload is a file and properties, so it has to be handled as upload
      case EntityType.TeamUpload:
        return this.updateTeamUpload(entity) as Promise<T>
      case EntityType.Project:
        // don't try to set the state to "inactive" (even when it is already inactive),
        // as this is forbidden by the API and would cause a validation error (for PM, admin, coordinator)
        if ((entity as IProject).state && (entity as IProject).state === ProjectState.Inactive) {
          delete (entity as IProject).state
        }
      // no break
    }

    // start the request: endpoint is encoded in the IRI/@id of every entity
    return this.put(entity["@id"], entity) as Promise<T>
  }


  /**
   * General function to delete a given entity via the backend api.
   * Entity-specific endpoint is encoded in the IRI/@id of every entity
   *
   * @param entity the entity to be deleted
   * @returns nothing after deleting the entity
   */
  public deleteEntity = (entity: INumericIdentifierModel): Promise<void> => {
    // special use cases
    switch (entityTypeFromIModelOrIRI(entity)) {
      // FeedbackPosts are not deleted, but its content to keep the discussion chain
      // therefor a special endpoint is used
      case EntityType.FeedbackPost:
        return this.deleteFeedbackPostContent(entity as IFeedbackPost)
    }

    // start the request: endpoint is encoded in the IRI/@id of every entity
    return this.delete(entity["@id"]) as Promise<void>
  }

  /**
   * General function to upload a file corresponding to an entity to a given endpoint
   *
   * @param uploadType the type of upload that should be performed
   * @param entity the entity to which the upload is connected
   * @param file the file that should be uploaded
   * @returns a Promise of the (changed) entity
   */
  public uploadFile = <T extends INumericIdentifierModel>(uploadType: UploadType, entity: INumericIdentifierModel, file: File): Promise<T> => {
    const formData = new FormData()
    formData.append("file", file)
    // use the uploadType-corresponding endpoint url and replace the placeholder [id] by the actual .id of the entity
    const url = uploadEndpointList[uploadType]?.url.replace("[id]", entity?.id.toString())
    return this.upload(url, file ? formData : null) as Promise<T>
  }

  // #endregion

  // #region general statistics function

  /**
   * General function to load statics
   * statistics-specific endpoint must be defined in the statisticsEndpointList.
   *
   * @param statisticsType type of statistics that should be loaded
   * @returns the statistics
   */
  public loadStatistics = <T extends IStatistics>(statisticsType: StatisticsType): Promise<T> => {
    // get the endpoint definition from the statisticsEndpointList
    const endpoint = statisticsEndpointList[statisticsType]

    // start the request and return results
    return this.get(endpoint.url) as Promise<T>
  }

  // #endregion


  /* *****************************************************************
   * specific functions that can not be handled by general functions
   *******************************************************************/


  // #region ProposalAttachment

  /**
   * An ProposalAttachment consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an IProposalAttachment to be sent to the API
   * @returns the API reply as IProposalAttachment
   */
  public createProposalAttachment = (entity: IProposalAttachment): Promise<IProposalAttachment> => {
    const formData = new FormData()

    if (entity.proposal) {
      formData.append("proposal", iriFromIModelOrIRI(entity.proposal))
    }
    if (entity.definition) {
      formData.append("definition", iriFromIModelOrIRI(entity.definition))
    }
    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }

    return this.upload(entityEndpointList[EntityType.ProposalAttachment].url, formData) as Promise<IProposalAttachment>
  }

  /**
   * An ProposalAttachment consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an IProposalAttachment to be updated
   * @returns the API reply as IProposalAttachment
   */
  public updateProposalAttachment = (entity: IProposalAttachment): Promise<IProposalAttachment> => {
    const formData = new FormData()

    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }

    return this.upload(entity["@id"], formData, { method: "PUT" }) as Promise<IProposalAttachment>
  }

  // #endregion

  // #region TeamUpload

  /**
   * An TeamUpload consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an ITeamUpload to be created
   * @returns the API reply as ITeamUpload
   */
  public createTeamUpload = (entity: ITeamUpload): Promise<ITeamUpload> => {
    const formData = new FormData()
    formData.append("project", iriFromIModelOrIRI(entity.project))

    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }
    if (typeof entity.category === "string") {
      formData.append("category", entity.category)
    }
    if (typeof entity.content === "string") {
      formData.append("content", entity.content)
    }
    return this.upload(entityEndpointList[EntityType.TeamUpload].url, formData) as Promise<ITeamUpload>
  }

  /**
   * An TeamUpload consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an ITeamUpload to be updated
   * @returns the API reply as ITeamUpload
   */
  public updateTeamUpload = (entity: ITeamUpload): Promise<ITeamUpload> => {
    const formData = new FormData()
    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }
    if (typeof entity.category === "string") {
      formData.append("category", entity.category)
    }
    if (typeof entity.content === "string") {
      formData.append("content", entity.content)
    }
    return this.upload(entity["@id"], formData, { method: "PUT" }) as Promise<ITeamUpload>
  }

  // #endregion


  // #region Auth
  public requestAuthToken = (credentials: ICredentials): Promise<IAuthReply> => {
    return this.post("/authentication_token", credentials) as Promise<IAuthReply>
  }

  public refreshAuthToken = (refreshToken: string): Promise<IAuthReply> => {
    return this.post("/refresh_token", { refresh_token: refreshToken }) as Promise<IAuthReply>
  }
  // #endregion

  // #region FeedbackInvitation

  public activateFeedbackInvitation = (fbi: IFeedbackInvitation): Promise<void> => {
    return this.post(`/feedback_invitations/${fbi.id}/activate`, fbi) as Promise<void>
  }
  // #endregion



  // #region FeedbackPost

  public deleteFeedbackPostContent = (post: IFeedbackPost): Promise<void> => {
    // not the feedbackpost is deleted, but its content
    return this.delete(post["@id"] + "/delete-content") as Promise<void>
  }

  // #endregion


  /* #region challenge */
  public transitionChallenge = (challenge: IChallenge, transition: ChallengeTransitionState): Promise<IChallenge> => {
    return this.post(challenge["@id"] + "/transition", {}, { params: { action: transition } }) as Promise<IChallenge>
  }

  /* #endregion */

  /* #region Proposals */
  public activateProposal = (proposal: IProposal | IFundApplication): Promise<void> => {
    return this.post(proposal["@id"] + "/activate", {}) as Promise<void>
  }

  public createProposalPdf = (proposal: IProposal | IFundApplication): Promise<void> => {
    return this.post(proposal["@id"] + "/create-pdf", []) as Promise<void>
  }

  public transitionProposal = (proposal: IProposal | IFundApplication, transition: ProposalTransitionState): Promise<IChallenge> => {
    return this.post(proposal["@id"] + "/transition", {}, { params: { action: transition } }) as Promise<IChallenge>
  }

  /* #endregion */


  /* #region Process */
  // @todo update when multi-processes are implemented
  public getCurrentProcess = async (): Promise<IProcess> => {
    return this.loadEntityCollection<IProcess>(EntityType.Process)
      .then((processes) => processes["hydra:member"].shift())
      // we cannot simply return the first process from the collection but need to fetch him separately
      // as his challenges are only returned in the detailResult.
      // We can also not assume that the ID is always 1...
      .then(process => process ? this.loadSingleEntity(EntityType.Process, process.id) : null)
  }

  public sendEmailToUsers = (processId: number, userMessage: IUserEmail): Promise<void> => {
    return this.post(`/processes/${processId}/contact-users`, userMessage) as Promise<void>
  }
  public sendContactEmail = (processId: number, email: IContactEmail): Promise<void> => {
    return this.post(`/processes/${processId}/contact`, email) as Promise<void>
  }
  // #endregion

  // #region Project

  // public createProject = (project: IProjectCreation | IProjectCreationWriteDTO | IProject): Promise<IProject> => {
  //   return this.post("/projects", project) as Promise<IProject>
  // }

  public createProjectPdf = (project: IProject): Promise<void> => {
    return this.post(project["@id"] + "/create-pdf", []) as Promise<void>
  }

  public reportProject = (project: IProject, report: IProjectReport): Promise<void> => {
    return this.post(project["@id"] + "/report", report) as Promise<void>
  }

  public emailProjectMembers = (project: IProject, email: ITeamEmail): Promise<void> => {
    return this.post(project["@id"] + "/team-email", email) as Promise<void>
  }

  // #endregion


  // #region User
  public registerUser = (userWriteDTO: IUserWriteDTO): Promise<IUser> => {
    return this.post("/users/register", userWriteDTO) as Promise<IUser>
  }

  public forgotPassword = (data: IPasswordResetRequest): Promise<void> => {
    return this.post("/users/reset-password", data) as Promise<void>
  }

  public changeEmail = (user: IUser, data: IEmailChange): Promise<void> => {
    return this.post(user["@id"] + "/change-email", data) as Promise<void>
  }

  public changePassword = (user: IUser, data: IPasswordChange): Promise<void> => {
    return this.post(user["@id"] + "/change-password", data) as Promise<void>
  }

  public newPassword = (user: IUser, data: INewPasswordRequest): Promise<void> => {
    return this.post(user["@id"] + "/new-password", data) as Promise<void>
  }

  // #endregion

  // #region Validation
  public confirmValidation = (data: IValidation): Promise<IActionResult> => {
    return this.post(`/validations/${data.id}/confirm`, data) as Promise<IActionResult>
  }

  public resetPassword = (data: IPasswordReset): Promise<IActionResult> => {
    return this.post(`/validations/${data.id}/confirm`, data) as Promise<IActionResult>
  }
  // #endregion



  /* #region Meta-Data */

  public getBackendCommit = (): Promise<string> => {
    return this.axios.request({ url: `/commit.txt` })
      .then(response => response.data as string)
  }


  public getSysinfo = (): Promise<ISysinfo> => {
    return this.get(`/sysinfo`) as Promise<ISysinfo>
  }

  // #endregion

}

export default new ProjektfabrikClient(FCP_API_ENTRYPOINT)
