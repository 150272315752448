import { withCallback } from "redux-saga-callback"
import { all, call, put, takeLatest } from "redux-saga/effects"

import apiClient from "@api/client"
import { ISysinfo } from "@api/schema"
import {
  PlatformActionTypes,
  setBackendCommitAction,
  setSysinfoAction
} from "@redux/actions/platform"

export function* platformWatcherSaga(): any {
  yield all([
    takeLatest(PlatformActionTypes.LoadBackendCommit, withCallback(loadBackendCommitSaga)),
    takeLatest(PlatformActionTypes.LoadSysinfo, withCallback(loadSysinfoSaga)),
  ])
}

function* loadBackendCommitSaga(): Generator<any, string, any> {
  try {
    const hash: string = yield call(apiClient.getBackendCommit)
    yield put(setBackendCommitAction(hash))
    return hash
  } catch (err) {
    yield put(setBackendCommitAction(''))
    return ''
  }
}

export function* loadSysinfoSaga(): Generator<any, ISysinfo, any> {
  try {
    const sysinfo: ISysinfo = yield call(apiClient.getSysinfo)
    yield put(setSysinfoAction(sysinfo))
    return sysinfo
  } catch (err) {
    // @todo proper error handling
    yield put(setSysinfoAction(null))
    return null
  }
}