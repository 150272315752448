// @todo: move other ActionTypes here, that are currently defined in the action-files
// to have a better overview, because: ActionTypes must be unique!


/**
 * existing EntityTypes
 */
export enum EntityType {
  Category = "categories",
  Discussion = "discussion",
  FeedbackInvitation = "feedbackInvitation",
  FeedbackPost = "feedbackPost",
  Challenge = "challenge",
  Proposal = "proposal",
  AttachmentDefinition = "attachmentDefinition",
  /** ChallengeConcretization is never loaded separately; only Create, Update, Delete and UploadFile actions */
  ChallengeConcretization = "challengeConcretization",
  Process = "process",
  /** Also valid for IProjectCreation type */
  Project = "project",
  ProjectFollowership = "projectFollowership",
  ProjectMembership = "projectMembership",
  ProposalAttachment = "proposalAttachment",
  /** SDGs can not be created, updated or deleted */
  Sdgs = "sdgs",
  TeamUpload = "teamUpload",
  User = "user",
}

/**
 * available statistics types
 */
export enum StatisticsType {
  Challenges = "challenges",
  Platform = "platform",
  Projects = "projects",
  User = "user",
}

/**
 * types that represent an option to upload a file
 *
 * to add new UploadTypes the uploadFileSaga must be extended to trigger the (re)load of depending entities
 * e.g. if a concretization image was uploaded, the corresponding IFund/IChallenge should be (re)freshed
 */
export enum UploadType {
  ConcretizationImage = "concretization_image",
  ChallengeLogo = "challenge_logo",
  ProcessLogo = "process_logo",
  ProjectPicture = "project_picture",
  ProjectVisualization = "project_visualization",
  UserPicture = "user_picture",

  // uploads connected to complex EntityTypes
  // ApplicationAttachment = "application_attachment",
  // TeamUpload = "team_upload",
}

/**
 * Types for general api actions
 */
export enum GeneralApiActionTypes {
  // EntityType related actions that are sent from Application level,
  // and trigger 'generalSaga`s that update state.data (scopedEntityReducer) and allow tracking of usecase-specific request status (via entityUsecaseReducer).
  Load = "_LOAD_ENTITY_",
  LoadCollection = "_LOAD_ENTITY_COLLECTION_",
  LoadCollectionPage = "_LOAD_ENTITY_COLLECTION_PAGE_",
  Create = "_CREATE_ENTITY_",
  Update = "_UPDATE_ENTITY_",
  Delete = "_DELETE_ENTITY_",
  Upload = "_UPLOAD_FILE_",

  // model "success" actions that are used by scopedEntityReducer (for state.data), entityUsecaseReducer (to cleanup state.entityUsecases), and maybe specific reducers
  LoadSuccess = "_LOAD_SUCCESS_",
  LoadCollectionSuccess = "_LOAD_COLLECTION_SUCCESS_",
  CreateSuccess = "_CREATE_ENTITY_SUCCESS_",
  UpdateSuccess = "_UPDATE_ENTITY_SUCCESS_",
  DeleteSuccess = "_DELETE_ENTITY_SUCCESS_",

  // tracking of request state
  NewUsecaseRequestRunning = "_NEW_USECASE_RUNNING_",
  NewUsecaseRequestSuccess = "_NEW_USECASE_SUCCESS_",

  // statistics
  LoadStatistics = "_LOAD_STATISTICS_",
  LoadStatisticsRunning = "_LOAD_STATISTICS_RUNNING_",
  LoadStatisticsSuccess = "_LOAD_STATISTICS_SUCCESS_",
}

/**
 * ActionTypes are combined from diverse general and specific types of actions
 */
export const ActionTypes = { ...GeneralApiActionTypes /* , ...AndereActionTypes */ }
export type ActionType = typeof ActionTypes


/**
 * Enumeration of available ScopeTypes/ registered use cases:
 * a ScopeType defines a kind of operation, to be used on a special entity type or some action connected to an entity
 *
 * The identifiers content must match to the EntityTypes!!
 */
export enum ScopeTypes {

  /** used as default usecaseKey for uploading a file in diverse situations (logos, images, ...) */
  UploadFileOperation = "_usecase_upload_file_operation",

  // general scopetypes are created by combining EntityType + action, e.g. project + _operation

  // specific ScopeTypes
  ChallengesForManager = "challenge_management",
  ChallengesForSelection = "challenges_for_selection",

  CategoriesLoading = "_usecase_categories_loading_",


  // NOTE no pages/component listens to state.requests of this ScopeType
  // it's only here b/c activateFeedbackInvitationSaga sadly sends those messages that no-one will ever hear ...
  FeedbackInvitationOperation = "feedbackInvitation_operation",

  FBIFromManager = "_usecase_fbi_from_manager_",
  FBIForPublic = "_usecase_fbi_for_public_",

  ProjectsForManager = "_usecase_project_management",
  ProjectsForUserManagement = "_usecase_user_management_projects",
  MyProjects = "_usecase_my_projects",

  PlatformOperation = "platform_operation",

  ProposalOperation = "proposal_operation",

  SdgsLoading = "_usecase_sdgs_loading",

  UserOperation = "user_operation", // still used for all not-yet-migrated user operations in sagas: currentUser, registration, users
  UserForManager = "_usecase_user_management",

  ValidationOperation = "validation_operation",
}


/**
 * defines accepted types for order-parameters by the API
 */
export enum OrderTypes {
  Asc = "asc",
  Desc = "desc"
}