import { Action } from "redux"

import { IProject } from "@api/schema"
import { IFormikActions } from "@redux/helper/actions"

/*
Actions for creating a new project idea
*/


/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum IdeaActionTypes {
  CreateIdea = "CREATE_IDEA",
  TriggerNotificationForUnusedIdea = "TRIGGER_NOTIFICATION_FOR_UNUSED_IDEA"
}

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IIdeaAction extends Action {
  type: IdeaActionTypes
}

export interface ICreateIdeaAction extends IIdeaAction {
  actions: IFormikActions
  idea: IProject
  type: IdeaActionTypes.CreateIdea
}

/**
 * Action should be triggered when an idea of a user is unused,
 * means: no project was created based on this idea yet.
 */
export interface ITriggerNotificationForUnusedIdeaAction extends IIdeaAction {
  type: IdeaActionTypes.TriggerNotificationForUnusedIdea
}

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const createIdeaAction = (idea: IProject, actions: IFormikActions): ICreateIdeaAction => ({
  actions,
  idea,
  type: IdeaActionTypes.CreateIdea,
})

export const triggerNotificationForUnusedIdeaAction = (): ITriggerNotificationForUnusedIdeaAction => ({
  type: IdeaActionTypes.TriggerNotificationForUnusedIdea
})
