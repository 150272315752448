import { putWait, withCallback } from "redux-saga-callback"
import { call, put, select, takeEvery } from "redux-saga/effects"

import apiClient from "@api/client"
import { IProject, IProposal, IUser } from "@api/schema"
import {
  IActivateProposalAction,
  ICreateProjectPdfAction,
  ICreateProposalPdfAction,
  IEmailTeamAction,
  ILoadMyProjectAction,
  ITransitionProposalAction,
  MyProjectsActionTypes
} from "@redux/actions/myProjects"
import { addNotificationAction } from "@redux/actions/notifications"
import {
  loadModelAction, loadModelBySlugOrIdAction, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction, usecaseRequestRunningAction
} from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { AppState } from "@redux/reducer"
import { EntityType, ScopeTypes } from "@redux/reduxTypes"
import { loadCurrentUserAction, selectLoadingCurrentUserUsecaseState } from "@redux/usecases/userAccount"
import { RequestErrorTranslations } from "@services/requestError"
import { SubmissionError } from "@services/submissionError"


// mapping: the defined Action is transformed into a corresponding Saga-Trigger
// so when an action is dispatched, the asynchroneous saga is spawned to interact with the API
// every single Saga is defined below
export function* myProjectsWatcherSaga(): any {
  yield takeEvery(MyProjectsActionTypes.LoadMyProject, withCallback(loadMyProjectSaga))
  yield takeEvery(MyProjectsActionTypes.CreateProjectPdf, withCallback(createProjectPdfSaga))
  yield takeEvery(MyProjectsActionTypes.CreateProposalPdf, withCallback(createProposalPdfSaga))
  yield takeEvery(MyProjectsActionTypes.EmailTeam, withCallback(emailTeamSaga))

  yield takeEvery(MyProjectsActionTypes.ActivateProposal, withCallback(activateProposalSaga))
  yield takeEvery(MyProjectsActionTypes.TransitionProposal, withCallback(transitionProposalSaga))
}

/**
 * saga to load a project to which the user is connected as a team member
 * but pre-checking if the currentUser is known (and has memberships).
 */
function* loadMyProjectSaga(action: ILoadMyProjectAction) {

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc
  const usecaseKey = action.slugOrId

  yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey))

  // ensure we have the user, we get the project IDs from his memberships
  const currentUser: IUser = yield putWait(loadCurrentUserAction())
  if (!currentUser) {
    const err: string = yield select((s: AppState) => selectLoadingCurrentUserUsecaseState(s)?.loadingError)
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, err))
    return null
  }

  // if the user isn't member (or applicant) of any project don't bother the API
  if (!currentUser.projectMemberships.length) {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, RequestErrorTranslations.NotFound))
    return null
  }

  // NOTE the logic to determine slug vs id is in generalSaga.loadSingleEntitySaga that handles loadModelBySlugOrIdAction
  const project: IProject = yield putWait(loadModelBySlugOrIdAction(EntityType.Project, action.slugOrId))

  yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Project, usecaseKey, project))
  return project
}


/**
 * saga to trigger the creation of a project-pdf
 */
function* createProjectPdfSaga(action: ICreateProjectPdfAction) {
  try {
    yield call(apiClient.createProjectPdf, action.project)
    yield put(addNotificationAction("message.project.pdfCreationTriggered", "success"))
  } catch (err) {
    if (err instanceof Error) {
      yield put(addNotificationAction(err.message, "error"))
    } else {
      // @todo
    }
  }
}

/**
 * saga to trigger the creation of an proposal-pdf
 */
function* createProposalPdfSaga(action: ICreateProposalPdfAction) {
  try {
    yield call(apiClient.createProposalPdf, action.proposal)
    yield put(addNotificationAction("message.project.pdfCreationTriggered", "success"))
  } catch (err) {
    if (err instanceof Error) {
      yield put(addNotificationAction(err.message, "error"))
    } else {
      // @todo
    }
  }
}

/**
 * saga to trigger a team-email
 */
function* emailTeamSaga(action: IEmailTeamAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey))
    yield call(apiClient.emailProjectMembers, action.project, action.email)

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Project, usecaseKey, null))

    if (onSuccess) {
      yield call(onSuccess, action.project)
    }

    return true
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}


/**
 * saga to activate a non-active project proposal
 */
function* activateProposalSaga(action: IActivateProposalAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation))
    const proposal: IProposal = yield call(apiClient.activateProposal, action.proposal)

    // reload the project to have a consistent model in the store, instead of integrating the
    // proposal manually, this also sets the request scope isLoading to false
    yield putWait(loadModelAction(EntityType.Project, (proposal.project as IProject).id, ScopeTypes.ProposalOperation))

    if (onSuccess) {
      yield call(onSuccess, proposal)
    }

    return proposal
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

/**
 * saga to transition a project proposal, e.g. submitting it
 */
function* transitionProposalSaga(action: ITransitionProposalAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation))

    const proposal: IProposal = yield call(apiClient.transitionProposal, action.proposal, action.transition)

    // reload the project to have a consistent model in the store, instead of integrating the
    // proposal manually, this also sets the request scope isLoading to false
    yield putWait(loadModelAction(EntityType.Project, (proposal.project as IProject).id, ScopeTypes.ProposalOperation))

    if (onSuccess) {
      yield call(onSuccess, proposal)
    }

    return proposal
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
