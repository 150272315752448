
import { DynamicNamespacesProps, I18nDictionary } from 'next-translate'
import I18nProvider from 'next-translate/I18nProvider'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'

import { loadLocaleFrom } from 'i18n'

/**
 * Adapted implementation to fix bug in original DynamicNamespaces implementation:
 * content inside DynamicNamespaces does not update immediately.
 *
 * Adapted solution from https://github.com/aralroca/next-translate/issues/591
 *
 * - no “dynamic” callback, b/c we don't load our i18n files in this dynamic way (the issue opener wants to load a file *per component* depending on widget.name)
 * - resolve Promise from loadNamespaces (typescript warning)
 * - move fallback condition before success code (code readability)
 * - other way to construct the React return value (don't know if it's necessary)
 *
 * @param props
 * @returns
 */
const DynamicNamespaces = ({
  namespaces = [],
  fallback,
  children
}: DynamicNamespacesProps): any => {
  const { locale } = useRouter()

  const [loaded, setLoaded] = useState<boolean>(false)
  const [pageNs, setPageNs] = useState<I18nDictionary[]>([])

  useEffect(() => {
    const loadNamespaces = async () => {
      const pageNamespaces = await Promise.all(namespaces.map((ns) => loadLocaleFrom(locale, ns) as Promise<I18nDictionary>))
      setPageNs(pageNamespaces)
      setLoaded(true)
    }

    loadNamespaces()
      // eslint-disable-next-line no-console
      .catch(console.error)
  }, [locale, namespaces])

  const structuredNamespaces = useMemo(() => {
    return namespaces.reduce((obj: Record<string, I18nDictionary>, ns, i) => {
      obj[ns] = pageNs[i]

      return obj
    }, {})
  }, [namespaces, pageNs])

  if (!loaded) {
    return fallback || null
  }

  return (React.createElement(I18nProvider, { lang: locale, namespaces: structuredNamespaces }, children))

}

export default DynamicNamespaces