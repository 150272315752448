import { putWait, withCallback } from "redux-saga-callback"
import { all, call, put, takeLatest } from "redux-saga/effects"

import apiClient from "@api/client"
import { IUser } from "@api/schema"
import { IUserWriteDTO, transformEntityToWriteDTO } from "@api/schema-dto"
import {
  IRegisterUserAction,
  RegistrationUsecases,
  processOnboardingDataAfterRegistrationAction,
  processOnboardingDataBeforeRegistrationAction,
} from "@redux/actions/registration"
import { createModelSuccessAction, usecaseRequestRunningAction, usecaseRequestSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { EntityType, ScopeTypes } from "@redux/reduxTypes"
import { Routes } from "@services/routes"
import { SubmissionError } from "@services/submissionError"
import { BASE_URL } from "config"

export function* registrationWatcherSaga(): any {
  yield all([
    takeLatest(RegistrationUsecases.RegisterUser, withCallback(registerUserSaga)),
  ])
}

function* registerUserSaga(action: IRegisterUserAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions

  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation))
    // the API uses {{param}} as placeholder while Next uses [param]:
    const route = Routes.ConfirmAccount.replace(/\[/g, "{{").replace(/\]/g, "}}")

    // transform entity to its DTO representation, if applicable
    let userWriteDTO: IUserWriteDTO = transformEntityToWriteDTO(EntityType.User, action.user)

    userWriteDTO = yield putWait(processOnboardingDataBeforeRegistrationAction(userWriteDTO))

    userWriteDTO.validationUrl = BASE_URL + route

    const newUser: IUser = yield call(apiClient.registerUser, userWriteDTO)

    yield put(createModelSuccessAction(EntityType.User, newUser))
    yield put(usecaseRequestSuccessAction(ScopeTypes.UserOperation, newUser))

    if (onSuccess) {
      yield call(onSuccess)
    }

    yield put(processOnboardingDataAfterRegistrationAction(newUser))

    return newUser
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
