import { putWait, withCallback } from "redux-saga-callback"
import { all, call, put, select, takeLatest } from "redux-saga/effects"

import { IProject, IProjectMembership, MembershipRole } from "@api/schema"
import { ICreateIdeaAction, IdeaActionTypes } from "@redux/actions/idea"
import { addNotificationAction } from "@redux/actions/notifications"
import { createModelAction, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/helper/actions"
import { selectCreatedIdeas, selectMyMemberships } from "@redux/reducer/myProjects"
import { EntityType } from "@redux/reduxTypes"
import { loadCurrentUserAction } from "@redux/usecases/userAccount"

export function* ideaWatcherSaga(): any {
  yield all([
    takeLatest(IdeaActionTypes.CreateIdea, withCallback(createIdeaSaga)),
    takeLatest(IdeaActionTypes.TriggerNotificationForUnusedIdea, unusedIdeaNotificationSaga),
  ])
}

/**
 * Create a new project idea for an already logged in user.
 *
 * @param action ICreateIdeaAction
 */
function* createIdeaSaga(action: ICreateIdeaAction) {
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc
  yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey))
  // we want to delay the call of onSuccess, cause essential data (user and his projects) have to be available before the onSuccess call (FCP-621)
  // save onSuccess for later call
  const onSuccess = action.actions.onSuccess
  // delete onSuccess on action.actions
  action.actions.onSuccess = null
  const idea: IProject = yield putWait(createModelAction(EntityType.Project, action.idea, action.actions))
  // NOTE / @todo: no createModelSuccessAction ?!

  // refresh the user to get the new idea in the list of his created ideas
  yield putWait(loadCurrentUserAction())

  yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Project, usecaseKey, idea))
  // call onSuccess, if defined, after necessary data is updated
  if (onSuccess) {
    yield call(onSuccess, idea)
  }

  return idea

  // NOTE / @todo: no error handling?!
}

/**
 * After the user logged in: check if the user has an idea without resulting
 * projects, if yes add a notification.
 */
function* unusedIdeaNotificationSaga() {
  const myIdeas: IProject[] = yield select(selectCreatedIdeas)
  if (myIdeas === null || myIdeas.length === 0) {
    return
  }

  const memberships: IProjectMembership[] = yield select(selectMyMemberships)
  const activeMemberships = memberships
    ? memberships.filter(m => m.role === MembershipRole.Coordinator || m.role === MembershipRole.Planner)
    : []

  const unusedIdeas = myIdeas.filter(i => !i.resultingProjects || i.resultingProjects.length === 0)
  // @todo prüfen bzw dokumentieren, warum auf activeMemberships geprüft wird
  if (unusedIdeas.length > 0 || activeMemberships.length === 0) {
    yield put(addNotificationAction("message.project.createFromIdeaReminder", "info"))
  }
}