import { Translate } from "next-translate"

import { ChallengeType, IChallenge, IFund } from "@api/schema"


// Currently not used, was used, when funds were available in the platform => could be useful again, when funds will be used in the platform
/**
 * challengeHelper: Collection of helper-functions for a IFund
 *
 */

/**
 * Path to the placeholder-image for funds
 */
export const fundLogoPlaceholder = "/assets/img/placeholder/fund-logo-placeholder.png"


/* ************************************************************************** */
/* helper-functions                                                           */
/* ************************************************************************** */

/**
 * Returns the list of materialcost-options as a usable option-list for forms
 *
 * @param fund
 * @returns a list of options for material costs, or null, if no fund is given or not materialcost-options are defined by the fund
 */
export const materialCostOptionsOfFund = (fund: IFund, t: Translate): { [key: string]: { printable: string } } => {

  // assamble materialCostTypes for the fund
  let materialCostOptionsFundDepended = {}
  if (fund) {
    fund.supportedMaterialCostTypes.forEach(smct =>
      materialCostOptionsFundDepended = { ...materialCostOptionsFundDepended, [t("common:resourceRequirement.costCategory." + smct)]: smct }
    )
    fund.customMaterialCostTypes.forEach(smct =>
      materialCostOptionsFundDepended = { ...materialCostOptionsFundDepended, [smct]: smct })
  }
  // return the list of options, if there is at least one option
  // or null, of the list is empty
  if (Object.keys(materialCostOptionsFundDepended).length > 0) {
    return materialCostOptionsFundDepended
  }
  else return null
}

/** returns, if a given challenge is of type Fund (and noch Challenge) */
export const isFund = (challenge: IChallenge): boolean => challenge && challenge['@type'] === ChallengeType.Fund