import { combineReducers } from "redux"

import { IAttachmentDefinition, ICategory, IChallenge, IChallengeConcretization, IDiscussion, IFeedbackInvitation, IFeedbackPost, INumericIdentifierModel, IProcess, IProject, IProjectFollowership, IProjectMembership, IProposal, IProposalAttachment, ISDG, ISlugAndNumericIdentifierModel, ITeamUpload, IUser, UserRole } from "@api/schema"
import { newLoadCollectionPageAction } from "@redux/helper/actions"
import { IFilteredCollectionState, emptyFilteredCollectionState, scopedObjectReducer } from "@redux/helper/reducers"
import { IIndexedCollectionState, IRequestState } from "@redux/helper/state"
import { AppState } from "@redux/reducer"
import { EntityType } from "@redux/reduxTypes"
import { hasMatches } from "@services/util"


// possible Models of Entities, organized in the state
export type ScopedModel = IChallenge | IProposal | IProcess | IProject | IUser

// Create object reducers for all entity types to prevent errors from typescript,
// even if we don't need the reducer and cannot replace it with a fake reducer like (state) => state
// NOTE the fields in this reducer are named EXACTLY like the value in EntityType.* according to the type parameter of scopedObjectReducer
export const dataReducer = combineReducers({
  attachmentDefinition: scopedObjectReducer<IAttachmentDefinition>(EntityType.AttachmentDefinition),
  categories: scopedObjectReducer<ICategory>(EntityType.Category),
  challenge: scopedObjectReducer<IChallenge>(EntityType.Challenge),

  // NOTE in theory, challengeConcretization "loaded data" field is superfluous, since EntityType.ChallengeConcretization entities
  // are always loaded & accessed from/with the Fund/Challenge: state.data.challengeConcretization is never read.
  // BUT it must exist as a field, since we need EntityType.ChallengeConcretization to stay in EntityType enum to be able
  // to handle challengeConcretization's CUD actions.
  // Therefore, we must keep it here, and it must be fully typed.
  challengeConcretization: scopedObjectReducer<IChallengeConcretization>(EntityType.ChallengeConcretization),

  discussion: scopedObjectReducer<IDiscussion>(EntityType.Discussion),
  feedbackInvitation: scopedObjectReducer<IFeedbackInvitation>(EntityType.FeedbackInvitation),
  feedbackPost: scopedObjectReducer<IFeedbackPost>(EntityType.FeedbackPost),
  process: scopedObjectReducer<IProcess>(EntityType.Process),
  project: scopedObjectReducer<IProject>(EntityType.Project),
  projectFollowership: scopedObjectReducer<IProjectFollowership>(EntityType.ProjectFollowership),
  projectMembership: scopedObjectReducer<IProjectMembership>(EntityType.ProjectMembership),
  proposal: scopedObjectReducer<IProposal>(EntityType.Proposal),
  proposalAttachment: scopedObjectReducer<IProposalAttachment>(EntityType.ProposalAttachment),
  sdgs: scopedObjectReducer<ISDG>(EntityType.Sdgs),
  teamUpload: scopedObjectReducer<ITeamUpload>(EntityType.TeamUpload),
  user: scopedObjectReducer<IUser>(EntityType.User),
})

/**
 * selectCollection is a help-function, that filters from the given state all Objects with a given type
 * (e.G. EntityType.Challenge) and returns them as an array of a given ScopedModel (e.G. <IChallenge>)
 * usage example, mostly in pages:
 * const mapStateToProps = (state: AppState) => ({
 * challenges: selectCollection<IChallenge>(state, EntityType.Challenge),
 * })
 *
 * If a typisation error occures here, after a new EntityType was added, the new EntityType must
 * get a reducer in the dataReducer above!
 */
export const selectCollection = <T extends ScopedModel>(state: AppState, scope: EntityType): T[] =>
  Object.values(state.data[scope] as IIndexedCollectionState<T>) as T[]

/**
 * extended version of selectCollection: it additionally filters those Objects, which ids are given
 * in a separate id-array
 */
export const selectCollectionByIds = <T extends ScopedModel>(state: AppState, scope: EntityType, ids: number[]): T[] =>
  ids.map((id) => state.data[scope][id] as T)


/**
 * shortcut to a IFilteredCollectionState of a collection use case
 *
 * @param state the AppState
 * @param entityType EntityType to be selected
 * @param usecaseKey use case for that EntityType to be selected
 * @returns the IFilteredCollectionState matching entitytype and usecaseKey
 */
export const selectCollectionUsecaseState = (state: AppState, entityType: EntityType, usecaseKey: string): IFilteredCollectionState => {
  const usecaseState = state.entityUsecases[entityType]?.filteredCollections?.[usecaseKey]
  return usecaseState
    ? {
      ...usecaseState,
      getItems: <T extends INumericIdentifierModel>() => selectCollectionByIds<T>(state, entityType, usecaseState.itemIds),
      getLoadNextPageAction: () => newLoadCollectionPageAction(entityType, usecaseState.nextLink, usecaseKey),
    } as IFilteredCollectionState
    : {
      // creating a copy instead of returning the global singleton
      ...emptyFilteredCollectionState
    }
}

/**
 * shortcut to a IRequestState of a single entity use case
 *
 * @param state the AppState
 * @param entityType EntityType to be selected
 * @param usecaseKey use case for that EntityType to be selected
 * @returns the IRequestState matching entitytype and usecaseKey
 */
export const selectSingleEntityUsecaseState = (state: AppState, entityType: EntityType, usecaseKey: string): IRequestState =>
  state.entityUsecases[entityType]?.singleEntities?.[usecaseKey]


/**
 * Allows to select a single Model via its ID. Set detailsRequired to true to only
 * receive a model that was loaded with its details (meaning not as part of a collection but as
 * single GET by ID).
 * If details are required and the Object is not returned, you need to trigger a reload from the API
 */
export const selectById = <T extends ScopedModel>(
  state: AppState,
  scope: EntityType,
  id: number,
  detailsRequired = false
): T => {
  const model = state.data[scope][id] as T
  if (!model) {
    return null
  }

  if (detailsRequired && !model.detailResult) {
    return null
  }

  return model
}

/**
 * Similar to selectById, but additionally checks if the given role was used for loading the model,
 * e.g. to make sure a model was loaded with higher privileges for more properties, instead of
 * being present in the store from a request before the login.
 */
export const selectByIdWithRole = <T extends ScopedModel>(
  state: AppState,
  scope: EntityType,
  id: number,
  role: UserRole | UserRole[],
  detailsRequired = false
): T => {
  const model = state.data[scope][id] as T
  if (!model || !model.usedRoles) {
    return null
  }

  if (detailsRequired && !model.detailResult) {
    return null
  }

  return hasMatches(model.usedRoles, role) ? model : null
}

/**
 * helper-function to select a user by its name
 */
export const selectUserByUsername = (state: AppState, username: string): IUser =>
  selectCollection<IUser>(state, EntityType.User)
    .filter((u) => u.username === username)
    .shift()

// #region special selectors for project and challenge by slugOrId (from slug component in routes)

/**
 * Generates a function that selects an entity from the store.
 *
 * @param entityType
 * @returns a function that selects an entity from the store.
 */
const generateSelectEntityBySlugOrId = <T extends ISlugAndNumericIdentifierModel>(entityType: EntityType): (state: AppState, slugOrId: string) => T =>
  (state: AppState, slugOrId: string) =>
    selectCollection<T>(state, entityType)
      .filter((f) => f.slug === slugOrId || f.id.toString() === slugOrId)
      .shift()

/**
 * Select a project that is specified by either its slug or ID.
 * Used when the slugOrId is a string from an URL, where both properties are allowed.
 */
export const selectProjectBySlugOrId = generateSelectEntityBySlugOrId<IProject>(EntityType.Project)

/**
 * Select a challenge that is specified by either its slug or ID.
 * Used when the slugOrId is a string from an URL, where both properties are allowed.
 */
export const selectChallengeBySlugOrId = generateSelectEntityBySlugOrId<IChallenge>(EntityType.Challenge)


/**
 * Selector to retrieve the loaded process.
 *
 * @todo rewrite for multi-process
 * @returns IProcess, may be empty
 */
export const selectCurrentProcess = (state: AppState): IProcess =>
  selectCollection<IProcess>(state, EntityType.Process).shift()

