import { putWait, withCallback } from "redux-saga-callback"
import { call, put, select, takeLatest } from "redux-saga/effects"

import apiClient from "@api/client"
import { IUser } from "@api/schema"
import { CurrentUserActionTypes, IChangeEmailAction, IChangePasswordAction } from "@redux/actions/currentUser"
import { usecaseRequestRunningAction, usecaseRequestSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { selectCurrentUser } from "@redux/reducer/auth"
import { ScopeTypes } from "@redux/reduxTypes"
import { loadCurrentUserAction, logoutAction } from "@redux/usecases/userAccount"
import { Routes } from "@services/routes"
import { SubmissionError } from "@services/submissionError"
import { BASE_URL } from "config"

export function* currentUserWatcherSaga(): any {
  yield takeLatest(CurrentUserActionTypes.ChangeEmail, withCallback(changeEmailSaga))
  yield takeLatest(CurrentUserActionTypes.ChangePassword, withCallback(changePasswordSaga))
  yield takeLatest(CurrentUserActionTypes.DeleteAccount, withCallback(deleteAccountSaga))
}

/**
 * Returns the current user if already in state or triggers to load it
 */
export function* getCurrentUser(): Generator<any, IUser, any> {
  let currentUser: IUser = yield select(selectCurrentUser)
  if (!currentUser || !currentUser.self) {
    currentUser = yield putWait(loadCurrentUserAction())
  }

  return currentUser
}

/**
 * saga to change users email-address
 */
function* changeEmailSaga(action: IChangeEmailAction) {
  const user: IUser = yield call(getCurrentUser)
  try {
    // the API uses {{param}} as placeholder while Next uses [param]:
    const route = Routes.ConfirmEmailChange.replace(/\[/g, "{{").replace(/\]/g, "}}")
    action.data.validationUrl = BASE_URL + route

    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation))
    // validation-URL is given to the API to include into the mail
    const res: boolean = yield call(apiClient.changeEmail, user, action.data)
    yield put(usecaseRequestSuccessAction(ScopeTypes.UserOperation, res))
    if (action.actions.onSuccess) {
      yield call(action.actions.onSuccess, res)
    }

    return res
  } catch (err) {
    if (err instanceof SubmissionError) {
      yield call(action.actions.setErrors, err.errors)
    } else if (err instanceof Error) {
      yield call(action.actions.setErrors, { error: err.message })
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation, errorMessage))
    if (action.actions.setSubmitting) {
      yield call(action.actions.setSubmitting, false)
    }

    return null
  }
}

/**
 * saga to change users password
 */
function* changePasswordSaga(action: IChangePasswordAction): Generator<any, any, any> {
  const user: IUser = yield call(getCurrentUser)
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation))
    const res: boolean = yield call(apiClient.changePassword, user, action.data)
    yield put(usecaseRequestSuccessAction(ScopeTypes.UserOperation, res))
    if (action.actions.onSuccess) {
      yield call(action.actions.onSuccess, res)
    }

    return res
  } catch (err) {
    if (err instanceof SubmissionError) {
      yield call(action.actions.setErrors, err.errors)
    } else if (err instanceof Error) {
      yield call(action.actions.setErrors, { error: err.message })
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    // signalise to the redux-system: request has stopped
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation, errorMessage))
    // signalise to the component, that triggered this saga by Action: submitting stopped
    if (action.actions.setSubmitting) {
      yield call(action.actions.setSubmitting, false)
    }

    return null
  }
}

/**
 * saga to delete a users account
 */
function* deleteAccountSaga(action: IChangePasswordAction) {
  const user: IUser = yield call(getCurrentUser)
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation))
    const res: boolean = yield call(apiClient.deleteEntity, user)
    yield put(usecaseRequestSuccessAction(ScopeTypes.UserOperation, res))
    yield put(logoutAction("message.auth.accountDeleted", Routes.Home))

    return res
  } catch (err) {
    if (err instanceof SubmissionError) {
      yield call(action.actions.setErrors, err.errors)
    } else if (err instanceof Error) {
      yield call(action.actions.setErrors, { error: err.message })
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(usecaseRequestRunningAction(ScopeTypes.UserOperation, errorMessage))
    if (action.actions.setSubmitting) {
      yield call(action.actions.setSubmitting, false)
    }

    return null
  }
}