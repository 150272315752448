import { Action } from "redux"

import { ValidationActionTypes } from "@redux/actions/validation"
import { IUsecaseRequestSuccessAction, REQUEST_PREFIX, SUCCESS_SUFFIX } from "@redux/helper/actions"
import { ScopeTypes } from "@redux/reduxTypes"

/** reduces the state to a boolean, if a validation-operation was successful (e.g. validation of account, mail address change e.g.) */
export const validationsReducer = (state = false, action: Action): boolean => {
  switch (action.type?.toUpperCase()) {
    case (REQUEST_PREFIX + ScopeTypes.ValidationOperation + SUCCESS_SUFFIX).toUpperCase():
      return (action as IUsecaseRequestSuccessAction).result as boolean

    case ValidationActionTypes.ResetValidationResult.toUpperCase():
      return false

    default:
      return state
  }
}
