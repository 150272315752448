import Link from "next/link"
import { useEffect } from "react"
import { connect, ConnectedProps } from "react-redux"
import { toast, ToastContainer as WrappedTC } from "react-toastify"
import { AnyAction, Dispatch } from "redux"

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { removeNotificationAction } from "@redux/actions/notifications"
import { AppState } from "@redux/reducer"
import { INotification, keysFromNotificationState, namespacesFromNotificationState } from "@redux/reducer/notifications"
import { addNamespacePrefixIfNotPresent, defaultNamespace, useDynamicTranslation } from "@services/i18n"
import { TOAST_AUTO_CLOSE_TIMEOUT } from "config"

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  removeNotification: (id: string) => dispatch(removeNotificationAction(id)),
})

const mapStateToProps = (state: AppState) => ({ notifications: state.notifications })

const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>

/**
 * Wrap the react-toastify container to get our notifications from the redux store
 * and translate them before displaying, as redux(-saga) has no access to the translation.
 * toasts are little messages to the user, explained here: https://ichi.pro/de/machen-sie-ihre-reactjs-mit-toastify-benutzerfreundlich-119594772770955
 *
 * @param Props
 */
const ToastContainer = ({ notifications, removeNotification }: Props) => {
  const t = useDynamicTranslation()

  /**
   * This block exists to prevent the display of "unloaded" i18n keys, as happened with https://futureprojects.atlassian.net/browse/FCP-1014
   * The funny thing is: b/c only the very first loading fails, we so-to-speak "circumvent" the failure by testing for it.
   *
   * true, if all keys from all notifications have been translated
   * false, if at least one of the contained keys is not translated
   */
  const isTranslated = keysFromNotificationState(notifications)
    .map(key => addNamespacePrefixIfNotPresent(defaultNamespace, key)) // make sure it has a namespace prefix
    // we pass `null` as namespace, b/c we know that the namespace is already in the key
    .map(key => key === t(null, key)) // is the translation is identical to the key?
    .filter(identical => identical === true) // leave only identical (un-translated)
    .length === 0 // true if no single i18n key is left

  useEffect(() => {
    // check is probably unnecessary, no sagas run server-side, no notifications should be created
    if (isTranslated && typeof window !== "undefined") {
      notifications.forEach((notification: INotification) => {
        toast(
          // NOTE we pass `null` as namespace for t(), b/c we don't know the namespace and we assume it's in the key
          typeof (notification.content) === "string"
            ? t(null, notification.content, notification.params)
            // if content is not a string, it's an object that defines a notification with button-link
            : <>
              <p>
                {t(null, notification.content.messageKey, notification.content.messageParams)}
              </p>
              <Link href={notification.content.route}>
                <a className="btn btn-primary">{t(null, notification.content.linkTitleKey, notification.content.linkTitleParams)}</a>
              </Link>
            </>
          , notification.options)
        removeNotification(notification.id)
      })
    }
  }, [notifications, isTranslated])

  return (
    <WrappedTC autoClose={TOAST_AUTO_CLOSE_TIMEOUT} />
  )
}

export default connector(
  withDynamicNamespaces<Props>(
    ToastContainer,
    // ToastContainer may be displayed on any page and must be able to show notification(s) from any usecase/entity/namespace.
    // The namespace(shortcut)s of those texts are stored in the according fields in `props` (fully qualified i18n keys).
    // To be able to translate those keys, we need to load exactly those namespaces, using the namespaceShortcuts from the props fields.
    (props: Props) => namespacesFromNotificationState(props.notifications)
  )
)
