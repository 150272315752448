import { putWait, withCallback } from "redux-saga-callback"
import { all, call, CallEffect, put, select, SelectEffect, takeEvery, takeLatest } from "redux-saga/effects"

import apiClient from "@api/client"
import { IProcess } from "@api/schema"
import { addNotificationAction } from "@redux/actions/notifications"
import { ILoadCurrentProcessAction, ISendContactEmailAction, ProcessActionTypes, loadCurrentProcessAction } from "@redux/actions/processes"
import {
  loadModelSuccessAction,
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction,
} from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { AppState } from "@redux/reducer"
import { selectCurrentProcess, selectSingleEntityUsecaseState } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"
import { SubmissionError } from "@services/submissionError"


/**
 * WatcherSaga, that watches on incoming Actions and calls the corresponding saga
 */
export function* processesWatcherSaga(): any {
  yield all([
    takeLatest(ProcessActionTypes.LoadCurrentProcess, withCallback(loadCurrentProcessSaga)),
    takeEvery(ProcessActionTypes.SendContactEmail, sendContactEmailSaga)
  ])
}

/**
 * Returns the current process if already in state or triggers to load it
 */
export function* getCurrentProcess(): Generator<SelectEffect | CallEffect<any>, IProcess, IProcess> {
  let currentProcess: IProcess = yield select(selectCurrentProcess)
  if (!currentProcess) {
    currentProcess = yield putWait(loadCurrentProcessAction())
  }

  return currentProcess
}


/**
 * Saga to load the current process
 *
 * @todo for rework: ScopeType umbenennen in LoadCurrentProcess um den Sonderfall klar abzugrenzen,
 * aber innerhalb der Saga die client.loadEntityCollection und die clientloadSingleEntity nutzen
 * NOTE: darauf achten, dass es im Multimandanten mehrere processes geben kann -> wie wird festgestellt, welcher process "current" ist?
 */
function* loadCurrentProcessSaga(action: ILoadCurrentProcessAction) {
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc
  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Process, usecaseKey))
    const currentProcess: IProcess = yield call(apiClient.getCurrentProcess)
    if (currentProcess) {
      yield put(loadModelSuccessAction(EntityType.Process, currentProcess))
    }
    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Process, usecaseKey, currentProcess))

    return currentProcess
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Process, usecaseKey, errorMessage))
    return null
  }
}


/**
 * saga to contact the process owners
 * especially when using the general contact form
 */
function* sendContactEmailSaga(action: ISendContactEmailAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Process, usecaseKey))
    const currentProcess: IProcess = yield call(getCurrentProcess)
    if (!currentProcess) {
      const err: string = yield select((s: AppState) => selectSingleEntityUsecaseState(s, EntityType.Process, ProcessActionTypes.LoadCurrentProcess)?.loadingError)
      yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Process, usecaseKey, err))
      return null
    }

    yield call(apiClient.sendContactEmail, currentProcess.id, action.contactEmail)

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Process, usecaseKey, null))

    yield put(addNotificationAction("message.process.contact.success", "success"))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    if (onSuccess) {
      yield call(onSuccess, currentProcess)
    }

    return true
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    yield put(addNotificationAction("message.process.contact.failure", "error"))

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Process, usecaseKey, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}
