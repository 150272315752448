import has from "lodash/has"
import { withCallback } from "redux-saga-callback"
import { all, call, put, takeLatest } from "redux-saga/effects"

import apiClient from "@api/client"
import { IActionResult } from "@api/schema"
import { addNotificationAction } from "@redux/actions/notifications"
import { IConfirmAccountAction, IConfirmChangeEmailAction, IResetPasswordAction, ValidationActionTypes } from "@redux/actions/validation"
import { usecaseRequestRunningAction, usecaseRequestSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { ScopeTypes } from "@redux/reduxTypes"
import { RequestErrorTranslations } from "@services/requestError"
import { SubmissionError } from "@services/submissionError"

export function* validationWatcherSaga(): any {
  yield all([
    takeLatest(ValidationActionTypes.ConfirmAccount, withCallback(confirmAccountSaga)),
    takeLatest(ValidationActionTypes.ConfirmChangeEmail, withCallback(confirmChangeEmailSaga)),
    takeLatest(ValidationActionTypes.ResetPassword, withCallback(confirmPasswordResetSaga)),
  ])
}

/**
 * saga to confirm a fresh user account and its mail
 */
function* confirmAccountSaga(action: IConfirmAccountAction) {
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.ValidationOperation))

    const result: IActionResult = yield call(apiClient.confirmValidation, action.data)

    yield put(addNotificationAction("message.account.validated", "success"))
    yield put(usecaseRequestSuccessAction(ScopeTypes.ValidationOperation, result.success))

    if (has(action, "actions.onSuccess")) {
      yield call(action.actions.onSuccess)
    }

    return result.success
  } catch (err) {
    if (err instanceof Error) {
      if (err.message === RequestErrorTranslations.NotFound) {
        err.message = "validation.account.notFound"
      }

      if (action.actions?.setErrors) {
        yield call(action.actions.setErrors, { error: err.message })
      }

      yield put(usecaseRequestRunningAction(ScopeTypes.ValidationOperation, err.message))

      if (action.actions?.setSubmitting) {
        yield call(action.actions.setSubmitting, false)
      }
    }

    return false
  }
}

/**
 * saga to confirm a change of the users mail-address of an existing account
 */
function* confirmChangeEmailSaga(action: IConfirmChangeEmailAction) {
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.ValidationOperation))
    const result: IActionResult = yield call(apiClient.confirmValidation, action.data)
    yield put(addNotificationAction("message.user.emailChanged", "success"))
    yield put(usecaseRequestSuccessAction(ScopeTypes.ValidationOperation, result.success))

    if (has(action, "actions.onSuccess")) {
      yield call(action.actions.onSuccess)
    }

    return result.success
  } catch (err) {
    if (err instanceof Error) {
      if (err.message === RequestErrorTranslations.NotFound) {
        err.message = "validation.confirmEmail.notFound"
      }

      yield action.actions
        ? call(action.actions.setErrors, { error: err.message })
        : put(usecaseRequestRunningAction(ScopeTypes.ValidationOperation, err.message))

      if (action.actions) {
        yield put(usecaseRequestRunningAction(ScopeTypes.ValidationOperation, err.message))
        if (action.actions.setSubmitting) {
          yield call(action.actions.setSubmitting, false)
        }
      }
    }

    return false
  }
}

/**
 * saga to confirm to reset the password of a user
 * within a "forgot password"-process
 */
function* confirmPasswordResetSaga(action: IResetPasswordAction) {
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.ValidationOperation))
    const result: IActionResult = yield call(apiClient.resetPassword, action.data)
    yield put(addNotificationAction("message.user.passwordResetComplete", "success"))
    yield put(usecaseRequestSuccessAction(ScopeTypes.ValidationOperation, result.success))

    if (has(action, "actions.onSuccess")) {
      yield call(action.actions.onSuccess, result)
    }

    return result.success
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(action.actions.setErrors, err.errors)
      } else {
        if (err.message === RequestErrorTranslations.NotFound) {
          err.message = "validation.resetPassword.notFound"
        }
        yield call(action.actions.setErrors, { error: err.message })
      }

      const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
      yield put(usecaseRequestRunningAction(ScopeTypes.ValidationOperation, errorMessage))
      if (action.actions.setSubmitting) {
        yield call(action.actions.setSubmitting, false)
      }
    }

    return null
  }
}
