/* eslint-disable */

// use JS instead of TS because this file is used by the non-transpiled server.js
var dateFns = require("date-fns")
var DEFAULT_LOCALE = require("../../locales/locales-config").DEFAULT_LOCALE
var SUPPORTED_LOCALES = require("../../locales/locales-config").SUPPORTED_LOCALES

/**
 * maps every (supported) locale to the date-fns of the locale
 * must be updated, when new languages are supported
 */
var localeToDateFns = {
  de: require(`date-fns/locale/de/index.js`),
  en: require(`date-fns/locale/en-GB/index.js`),
  // es: require(`date-fns/locale/es/index.js`),
}

// @todo node per default only includes small-icu (english)
var intlFormats = {
  date: { day: "2-digit", month: "2-digit", year: "numeric" },
  dateTime: { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" },
  longDate: { day: "numeric", month: "long", year: "numeric", weekday: "long" },
  longDateTime: {
    day: "numeric", month: "long", year: "numeric", weekday: "long", hour: "2-digit", minute: "2-digit"
  }
}

// define accepted formats for dateFns.format
// @see: https://date-fns.org/v2.29.2/docs/format
var formats = {
  date: "P",
  dateTime: "Pp",
  longDate: "PPPP",
  longDateTime: "PPPPp",
  month: "MMMM",
  monthYear: "LL'/'y",
  longMonthYear: "MMMM y",
  shortMonth: "MMM",
}


/**
 * returns the locale to handle date formats
 * uses the default locale if the given local is not supported
 * @param {string} locale sub-element of locales, defined in /locales/locales-config
 * @returns {object} matching date-fns
 */
function getLocaleToDateFns(locale) {

  // test on supported languages
  if (!SUPPORTED_LOCALES.includes(locale)) {
    // @todo log error? -> logUnhandledUnexpectedError
    locale = DEFAULT_LOCALE
  }

  // test on mapped date-fns
  return localeToDateFns[locale] || localeToDateFns[DEFAULT_LOCALE]
}

/**
 * 
 * @param {string} locale 
 * @param {string|number|Date} date 
 * @param {keyof typeof formats} format one of the keys of "formats" array
 * @returns {string} formatted date
 */
function formatDate(locale, date, format) {
  if (!date) {
    // @todo log error? -> logUnhandledUnexpectedError
    return "n/a"
  }

  if (typeof date === "string") {
    date = new Date(date)
  }

  if (!SUPPORTED_LOCALES.includes(locale)) {
    // @todo log error? -> logUnhandledUnexpectedError
    // use DEFAULT_LOCALE on invalid locale
    locale = DEFAULT_LOCALE
  }

  if (!formats[format]) {
    // @todo log error? -> logUnhandledUnexpectedError
    // use "formats.date" as fallback
    format = "date"
  }
  // @todo node per default only includes small-icu (english)
  // return new Intl.DateTimeFormat(locale, intlFormats[format]).format(date))

  return dateFns.format(date, formats[format], { locale: getLocaleToDateFns(locale) })
}

module.exports = { formats, formatDate, getLocaleToDateFns, localeToDateFns }