import { Action } from "redux"

import { IEmailChange, IPasswordChange } from "@api/schema"
import { IFormikActions } from "@redux/helper/actions"


/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum CurrentUserActionTypes {
  // LoadCurrentUser = "LOAD_CURRENT_USER",
  ChangeEmail = "CHANGE_EMAIL",
  ChangePassword = "CHANGE_PASSWORD",
  DeleteAccount = "DELETE_ACCOUNT",
}
/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface ICurrentUserAction extends Action {
  type: CurrentUserActionTypes
}

export interface IChangeEmailAction extends ICurrentUserAction {
  actions: IFormikActions
  data: IEmailChange
  type: CurrentUserActionTypes.ChangeEmail
}

export interface IChangePasswordAction extends ICurrentUserAction {
  actions: IFormikActions
  data: IPasswordChange
  type: CurrentUserActionTypes.ChangePassword
}

export interface IDeleteAccountAction extends ICurrentUserAction {
  actions: IFormikActions
  type: CurrentUserActionTypes.DeleteAccount
}

/**
 * Interface to combine all Actions
 */
export type CurrentUserActions =
  //  ILoadCurrentUserAction
  | IChangeEmailAction
  | IChangePasswordAction
  | IDeleteAccountAction

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const changeEmailAction = (data: IEmailChange, actions: IFormikActions): IChangeEmailAction => ({
  actions,
  data,
  type: CurrentUserActionTypes.ChangeEmail,
})

export const changePasswordAction = (data: IPasswordChange, actions: IFormikActions): IChangePasswordAction => ({
  actions,
  data,
  type: CurrentUserActionTypes.ChangePassword,
})

export const deleteAccountAction = (actions: IFormikActions): IDeleteAccountAction => ({
  actions,
  type: CurrentUserActionTypes.DeleteAccount,
})