import { withCallback } from "redux-saga-callback"
import { all, call, put, takeEvery } from "redux-saga/effects"

import apiClient from "@api/client"
import { IFeedbackInvitation } from "@api/schema"
import { FeedbackActionTypes, IActivateFeedbackInvitationAction } from "@redux/actions/feedback"
import {
  updateModelSuccessAction,
  usecaseRequestRunningAction,
  usecaseRequestSuccessAction
} from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { EntityType, ScopeTypes } from "@redux/reduxTypes"
import { SubmissionError } from "@services/submissionError"

// this file contains all specific sagas for the feedback-system


/**
 * bundling all single sagas to be registered in ./index.ts
 *
 * Mapping Entity-specific Actions onto sagas, which call the Entity-specific API-endpoints
 */
export function* feedbackWatcherSaga(): any {
  yield all([
    takeEvery(FeedbackActionTypes.ActivateFeedbackInvitation, withCallback(activateFeedbackInvitationSaga)),
  ])
}


function* activateFeedbackInvitationSaga(action: IActivateFeedbackInvitationAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.FeedbackInvitationOperation))
    const fbi: IFeedbackInvitation = yield call(apiClient.activateFeedbackInvitation, action.feedbackInvitation)

    yield put(updateModelSuccessAction(EntityType.FeedbackInvitation, fbi, /* action.type action.usecaseKey */))
    yield put(usecaseRequestSuccessAction(ScopeTypes.FeedbackInvitationOperation, fbi))

    if (onSuccess) {
      yield call(onSuccess, fbi)
    }

    return fbi
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    yield put(usecaseRequestRunningAction(ScopeTypes.FeedbackInvitationOperation, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}