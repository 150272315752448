/**
 * these are the (untranslated) errors the API returns if something goes wrong
 */
export enum RequestErrors {
  AccessDenied = "Access Denied.",
  BadRequest = "Bad Request",
  NotFound = "Not Found",
}

/**
 * this are the translateable messages we use when throwing an Error
 */
export enum RequestErrorTranslations {
  AccessDenied = "explanation.403",
  BadRequest = "explanation.500",
  NotFound = "explanation.404",
}

/**
 * we use this error subclass when the client encounters an error sending a request to the API
 * (not when a request received a response which contained an error!), for logging & retry purposes
 */
export class RequestError extends Error { }

export const translateRequestError = (msg: string): string => {
  switch (msg) {
    case RequestErrors.AccessDenied: return RequestErrorTranslations.AccessDenied
    case RequestErrors.BadRequest: return RequestErrorTranslations.BadRequest
    case RequestErrors.NotFound: return RequestErrorTranslations.NotFound
    default: return msg
  }
}
