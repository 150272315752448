import { Action } from "redux"

import { IProjectCreation } from "@api/schema"
import { IFormikActions } from "@redux/helper/actions"

/*
Actions for creating a new project
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum ProjectActionTypes {
  // don't use "CREATE_PROJECT", it collides with the general data actions -> reduxTypes.ts (ProjectActionTypes)
  CreateNewProject = "CREATE_NEW_PROJECT",
}

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IProjectAction extends Action {
  type: ProjectActionTypes
}

export interface ICreateProjectAction extends IProjectAction {
  actions: IFormikActions
  project: IProjectCreation
  type: ProjectActionTypes.CreateNewProject
}

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const createProjectAction = (project: IProjectCreation, actions: IFormikActions): ICreateProjectAction => ({
  actions,
  project,
  type: ProjectActionTypes.CreateNewProject,
})
